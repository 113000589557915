import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import App from "./App";
import './assets/styles/app.css';
import appTheme from "./assets/styles";

ReactDOM.render(
  <ThemeProvider theme={appTheme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
