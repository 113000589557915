import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      letterSpacing: "0.031rem",
      lineHeight: "1.9rem",
    },
  },
  
  subtitle: {
    color: "#fff",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  termsSection: {
    margin: "0px",
    padding: "0px 100px 0px 100px ",
    background: "#E5F7ED",
    paddingTop:"0px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  termsHeadings: {
    fontSize: "1.5rem",
    fontWeight: 600,
    textAlign: "center",
    margin: theme.spacing(0, 1),
    [theme.breakpoints.up("xxl")]: {
      fontSize: "2.4rem",
      margin: theme.spacing(2),
    },
  },
  termsContent: {
    lineHeight: "1.7rem",
    fontSize: "18px",
    textAlign: "justify",
    '& h2': {
      padding: "10px 0px",
    },
    '& ol': {
      paddingLeft: "30px",
    },
    "@media (max-width: 768px)": {
      fontSize: "14px",
      '& h2': {
        fontSize: "17px",
        textAlign: "left",
      },
      '& ol': {
        paddingLeft: "15px",
      },
    },
  },
}));

export default useStyles;
