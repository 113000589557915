import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppNavbar from "./components/shared/Navbar";
import Footer from "./components/shared/Footer";
import Homepage from "./pages/Home";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Solutions from "./pages/Solutions";
import GetInTouch from "./pages/GetInTouch";
import BlogPage from "./pages/Blogs";
import TermsAndCondition from "./pages/TermsAndConditon";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CareerPage from "./pages/career";
import OpeningJob from "./pages/opening";
import ContactUs from "./pages/ContactUs";

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <AppNavbar />
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/solutions">
            <Redirect to="/solutions/lifestore_pharmacy" />
          </Route>
          <Route exact path="/solutions/:solution_name" component={Solutions} />
          <Route exact path="/get-in-touch" component={GetInTouch} />
          <Route exact path="/blogs" component={BlogPage} />
          <Route exact path="/terms-and-condition" component={TermsAndCondition} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/career" component={CareerPage} />
          <Route exact path="/opening" component={OpeningJob} />
          <Route exact path="/contact-us" component={ContactUs} />
        </Switch>
      </Router>
      <Footer />
    </React.Fragment>
  );
}

export default App;
