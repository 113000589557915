import React from "react";
import {
  Grid, Typography, Button, Link, Hidden
} from "@material-ui/core";
import useStyles from "../../assets/styles/career";
import Team from "../../assets/images/Team.png"

export default function Career() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid item container className={classes.imgContainer}>
        <img
          src="https://res.cloudinary.com/health-id/image/upload/v1665058957/Lifestores%20Healthcare%20Website/careers_image.png"
          alt="Img"
          className={classes.imageTop}
        />
        <Grid item container direction="column" className={classes.joinWrapper}>
          <Typography className={classes.joinText}>Join Our Team</Typography>
          <Grid className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
            >
            <Link color="inherit" href="/opening">
              Current Openings
            </Link>
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.aboutContainer}>
          <Typography className={classes.testHeader}>Why Lifestores?</Typography>
          <Typography className={classes.aboutTest}>At Lifestores Healthcare, we are building a culture where amazing people like you
          can do their best work and have a large impact on their communities. We are mission-driven people who share common
          values and who are looking to transform access to primary healthcare in Nigeria and the entire Africa. If you are ready to
          work hard, grow your career, and positively affect people's lives, then this is the right place for you to be. Get in touch with
          us today!</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
