import React from "react";
import { Link, Typography, Grid, Hidden } from "@material-ui/core";
import useStyles from "../../assets/styles/ogaPharmacy";
import rightArrowBlue from "../../assets/images/Learn more - Blue.png";
import Solutions from "../../components/Solution";
import ogapharmacy from "../../assets/images/Ogapharmacy Logo.png";
import groupPurchaseIcon from "../../assets/images/Group Purchase Savings.png";
import innovativePatientIcon from "../../assets/images/Innovative Patient Services.png";
import n1mPlusIcon from "../../assets/images/N1M+ of Working Capital.png";
import pharmaManagementIcon from "../../assets/images/Pharma Management Software.png";

const informations = [
  {
    icon: groupPurchaseIcon,
    title: "Group Purchase Savings",
    description: "up to 10-15% procurement savings",
  },
  {
    icon: pharmaManagementIcon,
    title: "Pharma Management Software",
    description: "an all-in-one portal to manage pharmacy operations",
  },
  {
    icon: n1mPlusIcon,
    title: "N1M+ of Working Capital",
    description: "up to 1M+ revolving credit within 24hours & zero collateral",
  },
  {
    icon: innovativePatientIcon,
    title: "Innovative Patient Services",
    description: "Drive sales growth through our digital offerings",
  },
];

export default function Ogapharmacy() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.upper}>
        <div className={classes.upperSection}>
          <img
            className={classes.heroImage}
            alt="OGApharmacy_Solutions_Hero_Image"
            src="https://res.cloudinary.com/health-id/image/upload/v1613639350/Lifestores%20Healthcare%20Website/OGApharmacy_Solutions_Hero_Image.png"
          />
          <div className={classes.left}>
            <div className={classes.mainLogoWrapper}>
              <img
                className={classes.mainLogo}
                alt="_ls_healthcare_logo"
                src={ogapharmacy}
              />
            </div>
            <Typography
              variant="h2"
              component="header"
              className={classes.headings}
            >
              Built by pharmacists, for pharmacies
            </Typography>
            <Typography>
              <Link
                href="/about-us"
                className={`${classes.actions} ${classes.rightActionsHandler}`}
              >
                Get Started
                <img
                  className={classes.arrow}
                  alt="_arrow_"
                  src={rightArrowBlue}
                />
              </Link>
            </Typography>
          </div>
        </div>
        <div className={classes.imagesSection}>
          <img
            alt="OGApharmacy_Solutions_About_Image"
            src="https://res.cloudinary.com/health-id/image/upload/v1613639375/Lifestores%20Healthcare%20Website/OGApharmacy_Solutions_Hero_Image_2.png"
          />
        </div>
      </div>
      <div>
        <Grid container className={classes.middleSection}>
          <Grid item xs={12} md={6} className={classes.leftSection}>
            <div>
              <Typography
                variant="h2"
                component="header"
                color="secondary"
                className={classes.headings}
              >
                OGApharmacy is Nigeria ’s #1 Online Pharma Marketplace
              </Typography>
              <Typography color="secondary">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  color="inherit"
                  href="https://ogapharmacy.com/"
                  className={classes.actions}
                >
                  Learn more about OGApharmacy
                  <img
                    className={classes.arrow}
                    alt="_arrow_"
                    src={rightArrowBlue}
                  />
                </Link>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.yellow}>
              <div className={classes.imageWrapper}>
                <img
                  className={classes.image}
                  alt="_for_patients_avatar"
                  src="https://res.cloudinary.com/health-id/image/upload/v1613639409/Lifestores%20Healthcare%20Website/OGApharmacy_Solutions_About_Image.png"
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.blueSection}>
          {informations.map((information, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              className={classes.differences}
            >
              <div className={classes.valueLogoWrapper}>
                <img
                  alt="_icons+"
                  src={information.icon}
                  className={classes.valueLogo}
                />
              </div>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="secondary"
                className={classes.differenceHeading}
              >
                {information.title}
              </Typography>
              <Typography variant="body2" component="p" className={classes.difference}>
                {information.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Solutions />
      </div>
    </div>
  );
}
