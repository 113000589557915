import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "7rem",
    "& > *": {
      letterSpacing: "0.031rem",
      lineHeight: "1.9rem",
    },
  },

  imgContainer: {
    position: "relative",
  },
  imageTop: {
    width: "100%",
    // [theme.breakpoints.down("md")]: {
    //   display: "none"
    // },
  },
  joinWrapper: {
    alignItems: "center",
    position: "absolute",
    top: "10.5rem",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      position: "relative",
      top: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      top: "2rem",
    },
  },
  joinBox: {
    width: "513px",
    height: "202px",
    background: "#D9D9D9",
    opacity: "0.4",
    borderRadius: "30px",
    [theme.breakpoints.down("md")]: {
      width: "322px",
      height: "127px",
      borderRadius: "18px",
    },
    "@media (max-width: 350px)": {
      width: "250px",
      height: "100px",
      borderRadius: "15px",
    },
  },
  joinText: {
    fontWeight: "900",
    fontSize: "47px",
    lineHeight: "55px",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
      lineHeight: "52px",
      color: "#121212",
    },
    "@media (max-width: 350px)": {
      fontSize: "30px",
    },
  },
  buttonWrapper: {
    padding: "20px 73px",
    [theme.breakpoints.down("md")]: {
      padding: "15px 20px",
    },
  },
  button: {
    width: "214px",
    height: "57px",
    borderRadius: "15px",
    fontWeight: "600",
    fontSize: "20px",
    color: "#FFFFFF",
    "@media (max-width: 350px)": {
      width: "200px",
      height: "60px",
      fontSize: "18px",
    },
  },
  aboutContainer: {
    margin: "5rem 12rem",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      margin: "3rem",
    }
  },
  testHeader: {
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "45px",
    color: "#303030",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px"
    }
  },
  aboutTest: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "1.6",
    color: "#606060",
    marginTop: "15px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px"
    }
  },
  valueContainer: {
    justifyContent: "center",
    background: "#DEFFED",
    padding: "7rem 7rem 12rem",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      padding: "3rem",
    }
  },
  valueList: {
    justifyContent: "center",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "40px",
    color: "#121212",
    marginTop: "20px",
    "& ul": {
      marginTop: "10px",
    },
    "& li": {
      marginBottom: "20px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",

    }
  },
  valueImg: {
    marginRight: "50px"
  }
}));

export default useStyles;
