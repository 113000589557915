import React from "react";
import { Grid, Box, Container, Typography } from "@material-ui/core";
import useStyles from "../../../assets/styles/about";
import { WhatMUD } from "../variables";

const WMUD = () => {

  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.padContainer}>
      <Typography variant="h4" className={classes.headingTag}>
        What makes us different
      </Typography>

      <Grid container spacing={3}>
        {WhatMUD.map((value) => {
          return (
            <Grid item xs={12} md={4} xl={3} key={value?.id}>
              <Box
                borderRadius={15}
                paddingX={3}
                paddingY={4}
                className={classes.boxShadow}
                height={320}
              >
                <img src={value?.icon} alt="icon" />
                <div style={{ marginTop: "1rem" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "700", marginBottom: "1rem", color: "#303030" }}
                  >
                    {value?.title}
                  </Typography>
                  <Typography style={{fontSize: '14px'}}>{value?.desc}</Typography>
                </div>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default WMUD;
