import React from "react";
import Contact from "../../components/ContactUs/ContactUs";

export default function ContactUs() {
  document.title = "contact-us|| ls-healthcare";
  return (
    <div>
      <Contact />
    </div>
  );
}
