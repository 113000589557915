import React from "react";
import LsPharmacy from "./LsPharmacy";
import OgaPharmacy from "./OgaPharmacy";
import PharmIQ from "./PharmIQ";

export default function Solutions({ match }) {
  const {
    params: { solution_name },
  } = match;

  document.title = "solutions || ls-lifestores";

  switch (solution_name) {
    case "lifestore_pharmacy":
      return <LsPharmacy />;
    case "oga_pharmacy":
      return <OgaPharmacy />;
    case "pharmiq":
      return <PharmIQ />;
    default:
      return null;
  }
}
