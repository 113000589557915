import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../assets/styles/pharmIQ";
import IQ from "../../assets/images/IQ.PNG";
import listStyle from "../../assets/images/listStyle.PNG";
import getstarted from "../../assets/images/Learn more - Yellow.png";
import Solutions from "../../components/Solution";

export default function PharmIQ() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container className={classes.solution1}>
        <Grid item container xs={12} lg={6} className={classes.devices}>
          <img
            src="https://res.cloudinary.com/health-id/image/upload/v1613199951/Lifestores%20Healthcare%20Website/LH_Solutions_PharmIQ_Header_Image.png"
            className={classes.laptop}
            alt="_phone"
          />
        </Grid>
        <Grid item container xs={12} lg={6} className={classes.text}>
          <div className={classes.mainLogoWrapper}>
            <img
              className={classes.pharmIQLogo}
              alt="_ls_healthcare_logo"
              src="https://res.cloudinary.com/health-id/image/upload/v1611915229/Lifestores%20Healthcare%20Website/PharmIQ_Logo.png"
            />
          </div>
          <br />
          <Typography className={classes.pharmTitle}>
            The heartbeat of smart pharmacies
          </Typography>
          <br />
          <Typography className={classes.startedText}>
            Get Started
            <Grid item container className={classes.startedimg}>
              <img src={getstarted} className={classes.learn} alt="" />
            </Grid>
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.solution2}>
        <Grid item container xs={12} lg={6} className={classes.text2}>
          <Typography className={classes.textParagraph}>
            <br />
            <span className={classes.textOther}>
              PharmIQ,our technology solution for pharmacy management is
              equipped with features that improve your daily pharmacy operations
              to serve customers better. Its services are developed based on
              real feedback from pharmacists & patients in our self-managed
              stores.
            </span>
          </Typography>
          <Typography className={classes.learnmoreText}>
            Learn more about PharmIQ
            <Grid item container className={classes.startedimg}>
              <img src={getstarted} className={classes.learn} alt="" />
            </Grid>
          </Typography>
        </Grid>
        <Grid item container xs={12} lg={6} className={classes.raport}>
          <img
            className={classes.raportimg}
            src="https://res.cloudinary.com/health-id/image/upload/v1613199950/Lifestores%20Healthcare%20Website/LH_Solutions_PharmIQ_About_Image.png"
            alt="_rapport"
          />
        </Grid>
      </Grid>

      <Grid container className={classes.solution3}>
        <Grid item container xs={12} lg={6} className={classes.Ipadphone}>
          <Typography className={classes.IpadphoneText}>
            PharmIQ Features
          </Typography>
          <img
            src="https://res.cloudinary.com/health-id/image/upload/v1613199950/Lifestores%20Healthcare%20Website/LH_Solutions_PharmIQ_Features_Image.png"
            className={classes.Ipadphoneimg}
            alt="_Ipadphoneimg"
          />
        </Grid>
        <Grid item container xs={12} lg={6} className={classes.featuresList}>
          <Typography className={classes.feature1}>
            <img
              src={listStyle}
              className={classes.listStyle}
              alt="_listStyle"
            />
            Multi-outlet scalability
          </Typography>
          <Typography className={classes.feature}>
            <img
              src={listStyle}
              className={classes.listStyle}
              alt="_listStyle"
            />
            Easy to use Sales system with split payment
          </Typography>
          <Typography className={classes.feature}>
            <img
              src={listStyle}
              className={classes.listStyle}
              alt="_listStyle"
            />
            Customer loyalty programme
          </Typography>
          <Typography className={classes.feature}>
            <img
              src={listStyle}
              className={classes.listStyle}
              alt="_listStyle"
            />
            Inventory management
          </Typography>
          <Typography className={classes.feature}>
            <img
              src={listStyle}
              className={classes.listStyle}
              alt="_listStyle"
            />
            Expiry management
          </Typography>
          <Typography className={classes.feature}>
            <img
              src={listStyle}
              className={classes.listStyle}
              alt="_listStyle"
            />
            Automatic ordering based on store performance
          </Typography>
          <Typography className={classes.feature}>
            <img
              src={listStyle}
              className={classes.listStyle}
              alt="_listStyle"
            />
            Group procurement marketplace
          </Typography>
          <Typography className={classes.feature}>
            <img
              src={listStyle}
              className={classes.listStyle}
              alt="_listStyle"
            />
            Remote & Offline solutions
          </Typography>
          <Typography className={classes.feature}>
            <img
              src={listStyle}
              className={classes.listStyle}
              alt="_listStyle"
            />
            E-commerce integrations
          </Typography>
        </Grid>
      </Grid>
      <Solutions />
    </div>
  );
}
