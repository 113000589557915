import React from "react";
import {
  Typography, Grid, Card, CardActionArea, CardMedia,
  CardContent, Divider,
} from "@material-ui/core";
import useStyles from "../../assets/styles/blog";
import { blogs } from "./variables";
import submitImg from "../../assets/images/Send.png";
import downArrowGreen from "../../assets/images/See More.png";
import search from "../../assets/images/Search.png";

const MediaSection = ({ title, text, image, links }) => {
  const classes = useStyles();
  const handleClick = (link) => {
    window.open(link, "_blank").focus();
  };
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.card} onClick={() => handleClick(links)}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt={title}
            image={image}
            title={title}
            style={{ height: "17rem" }}
          />
          <CardContent>
            <Typography
              className={classes.cardTitle}
              variant="h5"
              component="h2"
            >
              {title}
            </Typography>
            <Divider />
            <Typography
              variant="subtitle2"
              color="textSecondary"
              component="p"
              className={classes.cardText}
            >
              {text}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default function Blogs() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.upperSection}>
        <Typography
          variant="h2"
          component="header"
          className={classes.mediaHeading}
        >
          Lifestores Healthcare Blog
        </Typography>
      </div>
      <div className={classes.betweenSection}>
        <div>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.resultText}
          >
            Showing
            <span style={{ fontWeight: 600 }}> 1-9 </span>
            of
            <span style={{ fontWeight: 600 }}> 9 </span>
            posts
          </Typography>
        </div>
        <div>
          <form className={classes.searchFrm}>
            <Grid container spacing={1}>
              <Grid item>
                <div className={classes.iconHolder}>
                  <img
                    className={classes.searchIcon}
                    alt="search_icon"
                    src={search}
                  />
                </div>
              </Grid>
              <Grid item xs={11}>
                <input
                  className={classes.searchInput}
                  type="search"
                  placeholder="Let's find what you're looking for"
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <div className={classes.media}>
        <Grid container spacing={10}>
          {blogs.map((blog, index) => (
            <MediaSection
              key={index}
              title={blog.title}
              links={blog.links}
              text={blog.text}
              image={blog.image}
            />
          ))}
        </Grid>
      </div>
      {/* <div className={classes.moreBtn}>
        <Typography>
          <Link href="/about-us" className={classes.actions}>
            Show more
            <img className={classes.arrow} alt="_arrow_" src={downArrowGreen} />
          </Link>
        </Typography>
      </div> */}
      <div className={classes.formSection}>
        <div className={classes.inputsThree}>
          <form className={classes.textareainput}>
            <Typography className={classes.inputHeader}>
              Share your email to stay tuned for latest news about Lifestores
              and healthcare in Africa
            </Typography>
            {/* <textarea
              className={classes.textarea}
              placeholder="Enter your email"
            ></textarea>
            <button className={classes.b}>
              <img
                className={classes.sendIconImg2}
                alt="_sendImage"
                src={submitImg}
              />
            </button> */}
          </form>
          <Typography>
            Email{" "}
            <em className={classes.email}>info@lifestoreshealthcare.com</em> to
            contribute
          </Typography>
        </div>
      </div>
    </div>
  );
}
