import React from "react";
import Privacy from "../../components/PrivacyPolicy/index";

export default function PrivacyPolicy() {
  document.title = "Privacy-Policy";
  return (
    <div>
      <Privacy />
    </div>
  );
}
