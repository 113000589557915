import { makeStyles } from "@material-ui/core/styles";
import ogaPharmacyBg from "../images/OGApharmacy.png";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(12),
    "& > *": {
      letterSpacing: "0.031rem",
      lineHeight: "2.0rem",
    },
  },
  upper: {
    display: "flex",
    minHeight: "calc(100vh - 70px)",
    flexFlow: "column",
    justifyContent: "flex-end",
    background: "#DEE6EF",
    [theme.breakpoints.up("xxl")]: {
      minHeight: "calc(100vh - 100px)",
    },
  },
  left: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: "1rem",
    },
  },
  upperSection: {
    paddingLeft: "20%",
    textAlign: "Right",
    padding: theme.spacing(2, 6),
    position: "relative",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0),
    },
  },
  mainLogoWrapper: {
    padding: "3rem",

    [theme.breakpoints.up("lg")]: {
      padding: "1rem",
    },
  },
  mainLogo: {
    width: "40vw",

    [theme.breakpoints.up("lg")]: {
      width: "18rem",
    },
  },
  imagesSection: {
    width: "100%",
    "&>img": {
      width: "100%",
    },
  },
  headings: {
    fontSize: "2.2rem",
    color: "#235A91",
    fontWeight: 600,
    letterSpacing: "0.08rem",
    marginTop: theme.spacing(1.2),
    [theme.breakpoints.up("xxl")]: {
      marginTop: theme.spacing(2.5),
      fontSize: "2.6rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.7rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      fontWeight: 600,
    },
  },
  subtitle: {
    margin: theme.spacing(3, 0),
    fontSize: "1.1rem",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
      marginBottom: theme.spacing(4),
    },
  },
  middleSection: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  heroImage: {
    position: "absolute",
    bottom: "0.0rem",
    height: "100%",
    left: 0,
    [theme.breakpoints.down("md")]: {
      position: "static",
      width: "90%",
    },
  },
  links: {
    marginBottom: "1.0rem",
  },
  arrow: {
    width: "1.0rem",
    height: "1.0rem",
    marginLeft: "1.2rem",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    color: "#235A91",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
      marginBottom: theme.spacing(6),
    },
  },
  leftSection: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      padding: "0 2.0rem",
    },
  },
  yellow: {
    backgroundImage: `url(${ogaPharmacyBg})`,
    backgroundSize: "90% 90%",
    backgroundRepeat: "no-repeat",
    margin: theme.spacing(4),
    marginBottom: theme.spacing(2),
    backgroundPosition: "center",
    padding: "5rem",
    [theme.breakpoints.down("md")]: {
      padding: "2.7rem",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2),
    },
  },

  imageWrapper: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    border: "solid 2px #00000029",
    padding: theme.spacing(1.5),
    borderRadius: ".5rem",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5),
    },
  },
  image: {
    width: "100%",
    borderRadius: ".5rem",
  },
  media: {
    background: "#f4f4f4",
    paddingLeft: theme.spacing(12),
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(4),
    boxSizing: "border-box",
    padding: theme.spacing(4, 6),
  },
  mediaHeading: {
    fontSize: "2.2rem",
    marginBottom: theme.spacing(8),
    textAlign: "center",
  },
  card: {
    maxWidth: 335,
    boxShadow: "0 0 10px #CCCCCC",
  },
  rightActionsHandler: {
    justifyContent: "flex-end",
  },
  blueSection: {
    background: "#235A91",
    color: "#fff",
  },
  difference: {
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.2rem",
    },
  },
  differences: {
    padding: theme.spacing(6),
    [theme.breakpoints.up("xxl")]: {
      padding: theme.spacing(12, 8),
    },
  },
  differenceHeading: {
    fontSize: "1.4rem",
    color: "#fff",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.6rem",
    },
  },
  valueLogoWrapper: {
    // marginTop: theme.spacing(2),
    width: "3rem",
  },
  valueLogo: {
    width: "100%",
  },
}));

export default useStyles;
