import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  solution: {
    padding: "5rem",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  solutions: {
    padding: "15px 10rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 2),
    },
    "& > p": {
      [theme.breakpoints.up("xxl")]: {
        fontSize: "1.4rem",
      },
    },
  },
  solutionHeading: {
    fontSize: "2.7rem",
    fontWeight: 700,
    textAlign: "center",
    textDecoration: "underline",
    textDecorationColor: "#CCCCCC",
  },
  webHolder: {
    margin: "10px 0",
    position: "relative",
    width: "100%",
    "&>div": {
      width: "100%",
      "&>img": {
        width: "100%",
      },
    },
  },
  logoWrapper: {
    width: "100%",
    height: "100%",
    padding: "10%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  mainLogo: {
    width: "80%",
  },
  mainLogoText: {
    fontSize: "1.2rem",
  },
  links: {
    marginBottom: "1.0rem",
  },
  arrow: {
    width: "1.5rem",
    height: "1.5rem",
    marginLeft: ".5rem",
  },
  powerDiv: {
    marginTop: "1rem",
    position: "relative"
  },
  poweredText: {
    fontStyle: "italic",
    color: "#a7a7a7"
  },
  pharmText: {
    fontSize: "1.3rem",
    fontWeight: 800
  },
  iq: {
    width: "1.5rem",
    height: "1.5rem",
    marginLeft: ".2rem",
    position: "absolute",
    top: "8px",
    borderRadius: "5px",
    boxShadow: "1px 1px 3px 0px #cecece"
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  lifestoreLink: {
    color: "#F58634",
  },
  ogapharmLink: {
    color: "#235A91",
    marginLeft: "2.0rem",
    "&:hover": {
      textDecorationColor: "#235A91",
    },
  },
  pharmiqLink: {
    color: "#A3A3A3",
    marginLeft: "2.5rem",
    "&:hover": {
      textDecorationColor: "#A3A3A3",
    },
  },
}));

export default useStyles;
