import React from "react";
import { Grid, Box, Container, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { values } from "../variables";
import useStyles from "../../../assets/styles/about";

const Values = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth={false} className={classes.padContainer} style={{marginBottom: "8rem"}}>
      <Typography variant="h4" className={classes.headingTag}>
        Our Core Values
      </Typography>

      <Grid container spacing={6}>
        {values.map((value) => {
          return (
            <Grid item xs={12} md={6} xl={6} key={value?.id}>
              <Box
                borderRadius={15}
                display={isMobile ? "block" : "flex"}
                p={4}
                gridGap={25}
                className={classes.boxCoreValues}
              >
                <img src={value?.icon} alt="icon" className={classes.coreVIcon} />
                <div>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "700", marginBottom: "1rem", color:"#303030" }}
                  >
                    {value?.title}
                  </Typography>
                  <Typography
                    style={{ fontSize: "14px" }}
                  >
                    {value?.desc}
                  </Typography>
                </div>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Values;
