import React from "react";
import Terms from "../../components/TermsAndCondition/index";

export default function TermsAndCondition() {
  document.title = "about-us || ls-healthcare";
  return (
    <div>
      <Terms />
    </div>
  );
}
