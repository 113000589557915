import React from 'react';
import PropTypes from 'prop-types';
import { Grid, 
  List, ListItem, Button, ListItemText 
} from '@material-ui/core';

import { RenderPopper } from './RenderPopper';
import useStyles from "../../assets/styles/downPopper";


const DropDownPopper = ({
  dropDownEl, handleDropDown,
}) => {
  const open = Boolean(dropDownEl);
  const classes = useStyles();



  return (
    <RenderPopper
      showArrow
      open={open}
      anchorEl={dropDownEl}
      onClickAway={() => handleDropDown()}
      popperPlacement="bottom-end"
      modifiers={[
        { name: 'offset', options: { offset: [15, 15] } },
      ]}
    >
      <Grid container item>
        <List className={classes.CList}>
          <ListItem disableGutters className={classes.CListItem}>
          <Button className={classes.CListItemButton} onClick={() => {
              window.open(
                "https://ogapharmacy.com",
                "_blank",
                "noopener"
              );
            }}>
              <ListItemText className={classes.CListItemText} primary="OGApharmacy (B2B)" />
            </Button>
          </ListItem>
          <ListItem disableGutters className={classes.CListItem}>
          <Button className={classes.CListItemButton} onClick={() => {
              window.open(
                "https://lifestorespharmacy.com",
                "_blank",
                "noopener"
              );
            }}>
              <ListItemText className={classes.CListItemText} primary="Lifestores Pharmacy (B2C)" />
            </Button>
          </ListItem>
        </List>
      </Grid>
    </RenderPopper>
  );
};

DropDownPopper.propTypes = {
  dropDownEl: PropTypes.instanceOf(Object),
  handleDropDown: PropTypes.func.isRequired
};

DropDownPopper.defaultProps = {
  dropDownEl: {},
};

export default DropDownPopper;
