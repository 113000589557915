import { makeStyles } from "@material-ui/core/styles";
const { pathname } = window.location;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#fff",

    // padding: "20px 32px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  subButton: {
    border: 0,
    borderRadius: 0,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: "#9A9EA6",
    "&:not(:first-child)": {
      marginLeft: "1.5rem",
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3rem",
      fontWeight: "900",
      alignSelf: "center",
      marginRight: 0,
      padding: "0 2rem",
      marginBottom: "1.5rem",
    },
  },
  activeSubButton: {
    color: "#09c25e",
    fontWeight: "800",
    [theme.breakpoints.down("md")]: {
      fontWeight: "900",
    },
  },
  contactButton: {
    color: "#fff",
    fontWeight: 600,
    background: "#F58634",
    padding: "0.8rem 2.6rem",
    width: "10.35rem",
    borderRadius: "9px",
    "&:hover": {
      background: "#F58634",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      fontWeight: "600",
      padding: "0.8rem 2rem",
      marginTop: ".8rem"
    },
  },
  title: {
    flexGrow: 1,
  },
  imgGrid: {
    // background: `${pathname === "/" ? "linear-gradient(135deg, rgba(201,253,207,1) 0%, rgba(255,255,255,1) 109%)" : "#fff"}`,
    background:  "#fff",
    alignItems: "center",
    paddingLeft: "6rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1.5rem",
      background: "#fff"
    },
    "@media (width: 1024px)": {
      paddingLeft: "3rem",
    }
  },
  navLogo: {
    cursor: "pointer",
    width: "32vw",

    [theme.breakpoints.up("lg")]: {
      width: "8rem",
    },
    [theme.breakpoints.up("xl")]: {
      width: "15rem",
    }
  },
  mainLogoWrapper: {
    padding: "1rem",

    [theme.breakpoints.up("lg")]: {
      padding: "1rem",
    },
  },
  mainLogo: {
    width: "40vw",
    marginTop: "1rem",

    [theme.breakpoints.up("lg")]: {
      width: "16rem",
    },
    [theme.breakpoints.up("xl")]: {
      width: "20rem",
    },
  },
  menuItems: {
    textAlign: "right",
    whiteSpace: "nowrap",
    padding: "2rem 6.1rem 2rem 2rem",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "1.2rem .8rem",
    },
    "@media (width: 1024px)": {
      padding: "2rem 3rem 2rem 0rem",
    }
  },
  button: {
    borderRadius: theme.spacing(2),
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
      alignSelf: "center",
      marginRight: 0,
      padding: "0 2rem",
    },
  },
  icon: {
    color: "#00B050",
    cursor: "pointer",
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  iconBtn: {
    padding: "0.7rem  0.7rem 0.7rem 7rem"
  },
  menuImg: {
    width: "1.5rem"
  },
  drawer: {
    background: "#E9FBF2",
    padding: "12rem 1.2rem 4rem",
  },
  dropdownOptions: {
    width: "30%",
    position: "fixed",
    overflow: "visible",
    backgroundColor: "#ffff",
    display: "block",
    top: "60px",
    zIndex: "90",
    boxShadow: "0px 3px 6px #00000029",
    border: " 0.01000000298023224px #CCCCCC",
    right: "0",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      width: "fit-content",
    },
    [theme.breakpoints.down("350")]: {
      width: "100%",
    },
    [theme.breakpoints.between("800", "992")]: {
      top: "100px",
    },
  },
  dropsubtext: {
    color: "#A3A3A3",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("290")]: {
      fontSize: "10.5px",
    },
  },
  dropdown: {
    cursor: "pointer",
    backgroundColor: "#ffffff",
  },
  dropdownButton: {
    width: "100%",
    marginBottom: "1.0rem",
    marginTop: "1.0rem",
    display: "flex",
    cursor: "pointer",
  },
  dropdownButtonLast: {
    width: "100%",
    display: "flex",
    cursor: "pointer",
    marginBottom: "1.0rem",
    marginTop: "1.0rem",
  },
  dropdownButtonFirst: {
    width: "100%",
    marginBottom: "1.0rem",
    marginTop: "1.0rem",
    display: "flex",
    cursor: "pointer",
  },
  dropimg: {
    maxWidth: "40px",
    maxHeight: "40px",
    display: "inline-block",
  },
  dropdowntext: {
    textAlign: "left",
    display: "block",
    marginLeft: "20px",
  },
  droptext: {
    textAlign: "left",
    fontWeight: "bold",
  },
}));

export default useStyles;
