import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../assets/styles/getInTouch";
import location from "../../assets/images/location.PNG";
import phonemessage from "../../assets/images/phoneMessage.PNG";
import email from "../../assets/images/message.PNG";
import insta from "../../assets/images/InstaIcon.PNG";
import linkedIn from "../../assets/images/linkedIcon.PNG";
import fbIcon from "../../assets/images/fbIcon.PNG";
import twitterIcon from "../../assets/images/twitterIcon.PNG";

export default function Green() {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={5} className={classes.contacts}>
      <Grid className={classes.contactsHeader}>
        <Typography className={classes.contactsHeaderText}>
          Contact Information
        </Typography>
      </Grid>
      <Grid className={classes.contactsList}>
        <Grid className={classes.contactsListItems}>
          <Grid className={classes.contactsListimg}>
            <img
              className={classes.listImages}
              alt="_lifestore_logo"
              src={location}
            />
          </Grid>
          <Typography className={classes.contactsListText}>
            273 Borno way, Yaba,
            <br />101212, Lagos, Nigeria.
          </Typography>
        </Grid>
        <Grid className={classes.contactsListItems}>
          <Grid className={classes.contactsListimg}>
            <img
              className={classes.listImages}
              alt="_lifestore_logo"
              src={phonemessage}
            />
          </Grid>
          <Typography className={classes.contactsListText}>
            +234 806 129 5352
          </Typography>
        </Grid>
        <Grid className={classes.contactsListItems}>
          <Grid className={classes.contactsListimg}>
            <img
              className={classes.listImages}
              alt="_lifestore_logo"
              src={email}
            />
          </Grid>
          <Grid>
            <Typography className={classes.contactsListText}>
              info@lifestoreshealthcare.com
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.contactsIcons}>
        <img className={classes.icons} alt="_lifestore_logo" src={insta} />
        <img className={classes.icons} alt="_lifestore_logo" src={linkedIn} />
        <img className={classes.icons} alt="_lifestore_logo" src={fbIcon} />
        <img
          className={classes.icons}
          alt="_lifestore_logo"
          src={twitterIcon}
        />
      </Grid>
    </Grid>
  );
}
