import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import { SwipeableDrawer, Hidden, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import DropDown from "./DropDown";
import useStyles from "../../assets/styles/navBar";
import Banner from "./Banner";
import { DropDownIcon } from '../../assets/svgs';
import DropDownPopper from './DropDownPopper'

const ElevationScroll = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const matches = useMediaQuery("(max-width:991px)");

  return React.cloneElement(children, {
    elevation: trigger ? 0 : 0,
    style: {
      backgroundColor: trigger ? "#fff" : "#fff",
      // opacity: matches ? 1 : trigger ? 1 : 0.8,
      opacity: 1,
      zIndex: 10001,
      boxShadow: "0px 4px 4px rgba(219, 219, 219, 0.25)"
    },
  });
};

export default function AppNavbar(props) {
  const classes = useStyles();
  const [width, setWidth] = useState("");
  const { pathname } = window.location;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dropDownEl, setDropDownEl] = useState(null);
  const navClass = pathname.split("/")[1];
  const history = useHistory();

  const navigations = [
    { name: "Home", link: "/" },
    { name: "About Us", link: "/about-us" },
    { name: "Careers", link: "/career" },
    // { name: "Contact Us", link: "/contact-us" },
    // { name: "Solutions", link: "/solutions" },
    //{ name: "Contanc Us", link: "/get-in-touch" },
    // { name: "Blog", link: "/blogs" },
    // { name: "Get in touch", link: "/get-in-touch" },
  ];
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen((_open) => !_open);
  };
  const handleButtonclick = (event, route) => {
    if (route === "/solutions") {
      setAnchorEl(event.currentTarget);
    }
    if (route && route !== "/solutions") {
      window.location = route;
    }
    if (open) setOpen(false);
  };

  const handleClick = () => {
    window.location = '/';
  };

  const handleDropDown = (event) => {
    setDropDownEl(dropDownEl ? null : event.currentTarget);
  };

  useEffect(() => {
    const width = window.screen.width;
    setWidth(width);
  }, []);

  return (
    <ElevationScroll {...props}>
      <AppBar className={classes.root} position="sticky">
        <Banner />
        <Grid container justify="flex-end">
          <Grid item container xs={4} className={classes.imgGrid}>
            <img
              className={classes.navLogo}
              onClick={handleClick}
              alt="_ls_healthcare_logo"
              src="https://res.cloudinary.com/health-id/image/upload/v1611062792/Lifestores%20Healthcare%20Website/Lifestores_Healthcare_Logo.png"
            />
          </Grid>
          <Grid container item xs={8} className={classes.menuItems}>
            <Hidden mdDown>
              {navigations.map((navigation) => (
                <Button
                  key={navigation.name}
                  className={clsx(classes.subButton, {
                    [classes.activeSubButton]:
                      navigation.link.split("/")[1] === navClass,
                  })}
                  onClick={(e) => handleButtonclick(e, navigation.link)}
                >
                  {navigation.name}
                </Button>
              ))}
              <Button
                className={clsx(classes.subButton)}
                onClick={handleDropDown}
              >
                Our Offerings&nbsp;&nbsp;
                <DropDownIcon style={{ fontSize: '.9rem' }} />
              </Button>
              <Button
                className={clsx(classes.subButton, classes.contactButton)}
                onClick={(e) => handleButtonclick(e, '/contact-us')}
              >
                Contact Us
              </Button>
            </Hidden>
            <Hidden lgUp>
              <IconButton onClick={toggleDrawer(true)} className={classes.iconBtn}>
                <img
                  className={classes.menuImg}
                  alt="_ls_healthcare_logo"
                  src="https://res.cloudinary.com/health-id/image/upload/v1665111468/Lifestores%20Healthcare%20Website/menu.png"
                />
              </IconButton>
              <SwipeableDrawer
                anchor="top"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                <Grid item container direction="column" className={classes.drawer}>
                  {navigations.map((navigation) => (
                    <Button
                      key={navigation.name}
                      className={clsx(classes.subButton, {
                        [classes.activeSubButton]:
                          navigation.link.split("/")[1] === navClass,
                      })}
                      onClick={(e) => handleButtonclick(e, navigation.link)}
                    >
                      {navigation.name}
                    </Button>
                  ))}
                  <Button
                    className={clsx(classes.subButton, classes.contactButton)}
                    onClick={(e) => handleButtonclick(e, '/contact-us')}
                  >
                    Contact Us
                  </Button>
                </Grid>
              </SwipeableDrawer>
            </Hidden>
          </Grid>
        </Grid>
        <DropDown anchorEl={anchorEl} setAnchorEl={setAnchorEl} width={width} />
        <DropDownPopper
          dropDownEl={dropDownEl}
          handleDropDown={handleDropDown}
        />
      </AppBar>
    </ElevationScroll>
  );
}
