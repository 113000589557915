import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      letterSpacing: "0.031rem",
      lineHeight: "2.5rem",
    },
  },
  media: {
    boxSizing: "border-box",
    padding: "3rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(6),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 2),
    },
  },
  upperSection: {
    padding: "2rem 3rem",
    background: "#E5F7ED",
  },
  betweenSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "3.5rem",
    paddingLeft: "3rem",
    "@media (max-width: 660px)": {
      flexFlow: "column-reverse",
      paddingLeft: "3rem",
      marginTop: "2rem",
    },
    "& > div": {
      width: "50%",
      "@media (max-width: 660px)": {
        width: "90%",
      },
    },
  },
  iconHolder: {
    width: "1.1rem",
    paddingBottom: "5px"
  },
  searchIcon: {
    width: "100%",
  },
  searchFrm: {
    borderBottom: "solid 2px #00B050",
    marginRight: "3rem",
    "@media (max-width: 660px)": {
      marginRight: "3rem",
      marginBottom: "2rem",
    },
  },
  searchInput: {
    width: "100%",
    border: "none",
    outline: "none",
    fontSize: "1rem"
  },
  mediaHeading: {
    fontSize: "2.5rem",
    fontWeight: 600,
  },
  mediaSubtitles: {
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  card: {
    boxShadow: "0px 0px 4px 1px #CCCCCC",
    borderRadius: "12px",
    "&:hover": {
      boxShadow: "0px 0px 4px 1px #00B050",
    },
    "@media (max-width: 600px)": {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  },
  cardTitle: {
    fontSize: "1.04rem",
    fontWeight: "bold",
    marginBottom: ".8rem"
  },
  cardText: {
    fontSize: "1.04rem",
    margin: "1rem 0 .5rem",
    lineHeight: 1.9
  },
  sendIconImg2: {
    height: "80px",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  formSection: {
    width: "100%",
    background: "#FCFCFC",
    "& > *": {
      boxSizing: "border-box",
    },
  },
  inputsThree: {
    paddingBottom: "50px",
    width: "100%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
  },
  b: {
    position: "absolute",
    bottom: "-40px",
    right: "-12px",
    zIndex: "20",
    marginTop: "20px",
    borderRadius: "50%",
    border: "none",
    background: "none",
    cursor: "pointer",
    outline: "none",
  },
  resultText: {
    color: "#A3A3A3",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  inputHeader: {
    fontSize: "2.0rem",
    fontWeight: 500,
    marginBottom: theme.spacing(8),
    "@media (max-width: 600px)": {
      fontSize: "1.5rem",
    },
  },
  textareainput: {
    margin: "10px",
    marginBottom: "6rem",
    position: "relative",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "55%",
    "@media (max-width: 600px)": {
      width: "90%",
    },
  },
  textarea: {
    paddingLeft: "10px",
    borderRight: "none",
    borderLeft: "none",
    borderTop: "none",
    borderWidth: "3px",
    borderColor: "#f0f0f0",
    outline: "none",
    width: "100%",
  },
  email: {
    color: "#235A91",
    textDecoration: "underline",
  },
  arrow: {
    width: "1.0rem",
    height: "1.0rem",
    marginLeft: "1.2rem",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  moreBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(10),
  },
}));

export default useStyles;
