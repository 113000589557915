import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      letterSpacing: "0.031rem",
      lineHeight: "2.5rem",
    },
  },
  solution: {
    paddingRight: theme.spacing(2, 4),
    zIndex: "1",
  },
  solutions: {
    padding: theme.spacing(5, 6),
    textAlign: "center",
  },
  IpadphoneText: {
    marginLeft: "11vw",
    color: "#FCED50",
    fontWeight: "600",
    fontFamily: "Mulish",
    fontSize: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down("450")]: {
      marginLeft: "0vw",
    },
  },
  Ipadphone: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("450")]: {
      alignItems: "center",
    },
  },
  solution2: {
    marginTop: theme.spacing(1),
    paddingTop: "130px",
    paddingBottom: "130px",
  },
  solution3: {
    background: "#424242 0% 0% no-repeat padding-box",
    alignItems: "center",
    paddingTop: "80px",
    paddingBottom: "80px",
    marginBottom: "50px",
  },
  devices: {
    display: "inline-flex",
    alignItems: "flex-end",
    flexWrap: "nowrap",
  },
  phone: {
    height: "120px",
  },
  laptop: {
    alignSelf: "center",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  text: {
    display: "block",
    alignSelf: "center",
    textAlign: "right",
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(2),
  },
  paragraph: {
    textAlign: "right",
    float: "right",
    color: "#ffffff",
    letterSpacing: "0.031rem",
    lineHeight: "2.5rem",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  iq: {
    height: "50px",
    [theme.breakpoints.between("md", "lg")]: {
      height: "80px",
    },
    [theme.breakpoints.between("1593", "1700")]: {
      height: "60px",
    },
  },
  pharmTitle: {
    fontSize: "35px",
    marginBottom: "10px",
    color: "#fced50",
    textAlign: "right",
    float: "right",
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize: "2.4rem",
    },
  },
  mainLogoWrapper: {
    padding: "2rem",
    [theme.breakpoints.up("lg")]: {
      padding: "1rem",
    },
  },
  pharmIQLogo: {
    width: "0px",

    [theme.breakpoints.up("lg")]: {
      width: "12rem",
    },
  },
  learn: {
    height: "20px",
  },
  startedimg: {
    marginLeft: "10px",
  },
  startedText: {
    color: "#707070",
    whiteSpace: "nowrap",
    // marginRight: "30px",
    textAlign: "right",
    marginTop: "30px",
    float: "right",
    display: "flex",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  raportimg: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  Ipadphoneimg: {
    maxWidth: "100%",
    maxHeight: "100%",
    marginLeft: "10vw",
    [theme.breakpoints.down("450")]: {
      marginLeft: "0",
      alignItems: "center",
    },
  },
  featuresList: {
    display: "flex",
    flexDirection: "column",
    alignContent: "space-around",
  },
  feature: {
    color: "#ffffff",
    letterSpacing: "0.5px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
  },
  feature1: {
    marginTop: "30px",
    color: "#ffffff",
    letterSpacing: "0.5px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
  },
  listStyle: {
    height: "20px",
    marginRight: "10px",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  solutionHeading: {
    fontSize: "2.7rem",
    fontWeight: 700,
    textAlign: "center",
    textDecoration: "underline",
    textDecorationColor: "#CCCCCC",
    marginBottom: theme.spacing(4),
  },
  text2: {},
  textTitle: {
    letterSpacing: "0.88px",
    color: "#A3A3A3",
    fontWeight: "bold",
    fontSize: "2rem",
  },
  textParagraph: {
    marginLeft: "30px",
    marginRight: "100px",
    [theme.breakpoints.down("996")]: {
      marginLeft: "30px",
      marginRight: "40px",
    },
  },
  textOther: {
    textAlign: "left",
    letterSpacing: "0.031rem",
    lineHeight: "2.5rem",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  learnmoreText: {
    color: "#707070",
    whiteSpace: "nowrap",
    // width:"100%",
    marginLeft: "30px",
    textAlign: "right",
    marginTop: "30px",
    marginBottom: "30px",
    float: "right",
    display: "flex",
    flexWrap: "nowrap",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.down("270")]: {
      fontSize: "11.6px",
    },
  },
  solution1: {
    background: "#A3A3A3 0% 0% no-repeat padding-box",
    marginTop: theme.spacing(1),
    height: "90vh",
    [theme.breakpoints.down("lg")]: {
      paddingBottom: "70px",
      paddingTop: "70px",
      height: "auto",
    },
  },
  logoWrapper: {
    width: "100%",
    height: "100%",
    padding: "10%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  mainLogo: {
    width: "80%",
  },
  links: {
    marginBottom: "1.0rem",
  },
  arrow: {
    width: "1.0rem",
    height: "1.0rem",
    marginLeft: ".9rem",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.85rem",
  },
  lifestoreLink: {
    color: "#F58634",
  },
  ogapharmLink: {
    color: "#235A91",
    marginLeft: "2.0rem",
    "&:hover": {
      textDecorationColor: "#235A91",
    },
  },
  pharmiqLink: {
    color: "#A3A3A3",
    marginLeft: "2.5rem",
    "&:hover": {
      textDecorationColor: "#A3A3A3",
    },
  },
}));

export default useStyles;
