export const blogs = [
  {
    title: "Anchors and Pillars: Pharmacies, the main…",
    links:
      "https://businessday.ng/features/article/anchors-and-pillars-pharmacies-the-mainstay-of-nigerias-healthcare/",
    text:
      "The COVID-19 pandemic has exposed the limitations and in some cases, inadequacies of healthcare systems, globally – with some region…",
    image:
      "https://149520306.v2.pressablecdn.com/wp-content/uploads/2021/02/Covid19.jpg",
  },
  {
    title: "Online Pharma Marketplace: OGApharma…",
    links:
      "https://www.pharmanewsonline.com/online-pharma-marketplace-ogapharmacy-virtual-pre-launch-holds-today/",
    text:
      "Lifestores Healthcare pre-launches OGApharmacy, an online B2B pharmaceutical marketplace dedicated to making quality health…",
    image:
      "https://www.pharmanewsonline.com/wp-content/uploads/2020/11/OGA-PR-img1-e1606373195887.jpeg",
  },
  {
    title: "Inside Nigeria’s Community Pharmacies",
    links:
      "https://medium.com/@info_30468/inside-nigerias-community-pharmacies-where-patients-go-to-avoid-long-hospital-queues-5ce5acddb580",
    text:
      "When many Nigerians face health challenges, they don’t immediately go to the hospital. Instead, they visit a pharmacy or an unofficial health serv…",
    image: "https://miro.medium.com/max/700/1*8edfr4gkmwbgQSrT_5zXZg@2x.jpeg",
  },
  {
    title: "Announcing the Lifestores Board",
    links:
      "https://medium.com/@info_30468/announcing-the-lifestores-board-b1f90317f275",
    text:
      "We started Lifestores Healthcare three years ago with a bold vision: a world with equal access to essential primary healthcare, regardless of what…",
    image: "https://miro.medium.com/max/700/1*OL0otRc2Knbp9B1nLUBfFA.png",
  },
  {
    title: "Breathing New Life into the African Phar…",
    links:
      "https://healthtransformer.co/breathing-new-life-into-the-african-pharmacy-747850646d26",
    text:
      "Typically, in the U.S., a person patronizes a drugstore to purchase diapers, snacks, cosmetics, greeting cards, laundry deterg…",
    image: "https://miro.medium.com/max/1000/1*Ze8KtCqC8nvLCtGKinxm1Q.jpeg",
  },
  {
    title: "Pharmacy Startup Raises 1M Seed Round",
    links:
      "https://qz.com/africa/1808040/nigeria-pharmacy-starup-lifestores-raises-1-million-seed-round/",
    text:
      "Over the past decade, Nigerian startups have popped up with solutions across several sectors from financial services to logistics…",
    image:
      "https://cms.qz.com/wp-content/uploads/2020/02/AP_20038343844451-e1582645991597.jpg?quality=75&strip=all&w=1900&h=1068",
  },
  {
    title: "Innovators Creating Prosperity: Lifestores…",
    links:
      "https://www.christenseninstitute.org/blog/innovators-creating-prosperity-lifestores-healthcare/",
    text:
      "In Nigeria pharmacies play an elevated role in delivering primary healthcare to the community, with people often treating …",
    image:
      "https://res.cloudinary.com/health-id/image/upload/v1611233527/Lifestores%20Healthcare%20Website/Media_1_Innovators_Creating_Prosperity.png",
  },
  {
    title: "Lifestores wants to change Nigeria’s Phar…",
    links:
      "https://techcabal.com/2019/10/07/lifestores-wants-to-change-nigerias-pharma-business-using-technology/",
    text:
      "Lifestores is a medical technology company that is bringing innovation to how the pharmacy business works in Nigeria.",
    image:
      "https://res.cloudinary.com/health-id/image/upload/v1611233540/Lifestores%20Healthcare%20Website/Media_2_Lifestores_wants_to_change_Nig.png",
  },
  {
    title: "Meet the Health Transformers Working To…",
    links:
      "https://healthtransformer.co/meet-the-health-transformers-working-toward-health-equity-at-the-the-2020-startup-health-festival-e1c2f994d9a3",
    text:
      "20+ Health Transformers at the 2020 StartUp Health Festival who are opening up access care for those who need it most.",
    image:
      "https://res.cloudinary.com/health-id/image/upload/v1611233547/Lifestores%20Healthcare%20Website/Media_3_Meet_the_Health_Transformer.png",
  },
];
