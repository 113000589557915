import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: "url('https://res.cloudinary.com/health-id/image/upload/v1664550290/Lifestores%20Healthcare%20Website/background_image.svg')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height:'36rem',
        [theme.breakpoints.down("md")]: {
            height:'25rem'
        }
    },
    headerText: {
        fontSize: "4em",
        fontWeight: "610",
        textAlign: "center",
        paddingTop: "140px",
        color: "rgba(48, 48, 48, 1)",
        [theme.breakpoints.down("md")]: {
            fontSize: "2.2em",
            paddingTop: "80px"
        }
    },
    headerText2: {
        fontSize: "4em",
        fontWeight: "610",
        textAlign: "center",
        color: "rgba(48, 48, 48, 1)",
        [theme.breakpoints.down("md")]: {
            fontSize: "2.2em"
        }
    },
    subtitleText: {
        fontSize: "1.4em",
        marginTop: "30px",
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            fontSize: "1.2em",
            marginTop: "30px"
        }
    },
    contactEmail: {
        color: "rgba(245, 134, 46, 1)",
    },
    imageBox: {
        textAlign: "center",
        margin: "150px 0 70px 0",
        position: "relative",
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    },
    imageDown: {
        position: "relative",
        top: "-10px",
        zIndex: 1,
        [theme.breakpoints.down("md")]: {
          display: "none"
        }
    },
    imageUp: {
        top: "-70px",
        right: "220px",
        position: "absolute",
        zIndex: 2,
        [theme.breakpoints.up("xxl")]: {
            top: "-65px",
            right: "320px",
        },
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    },
    formBox: {
        border: "1px solid #F58634",
        borderRadius: "40px",
        display: "block",
        margin: "0 auto 70px auto",
        height: "600px",
        width: "80%",
        [theme.breakpoints.down("md")]: {
            marginTop: "50px",
            width: "90%",
        }
    },
    inputBox: {
        padding: "70px",
        [theme.breakpoints.down("md")]: {
            padding: "10px"
        }
    },
    input: {
        backgroundColor: "#F5F6FA",
        borderRadius: "20px",
        height: "60px",
        marginTop: "20px",
        "& .MuiInputBase-input": {
            padding: "30px",
            paddingTop: "15px",
            fontWight: "400",
            fontSize: "19.963px",
            lineHeight: "16px"
        }
    },
    messageInput: {
        backgroundColor: "#F5F6FA",
        borderRadius: "20px",
        height: "200px",
        marginTop: "20px",
        "& .MuiInputBase-input": {
            padding: "30px",
            paddingTop: "20px",
            fontWight: "400",
            fontSize: "19.963px",
            lineHeight: "16px"
        }
    },
    submitBtn: {
        backgroundColor: "#F58634",
        borderRadius: "15px",
        color: "#fff",
        fontSize: "1.5em",
        height: "60px",
        marginTop: "50px",
        width: "220px"
    }
}));


export default useStyles;
