import React from "react";
import Openings from "../../components/Opening/index";

export default function OpeningJob() {
  document.title = "opening-job || ls-healthcare";
  return (
    <div>
      <Openings />
    </div>
  );
}
