import React from "react";
import GetinTouch from "../../components/GetInTouch/GetInTouch";

export default function AboutUs() {
  document.title = "get-in-touch || ls-healthcare";
  return (
    <div>
      <GetinTouch />
    </div>
  );
}
