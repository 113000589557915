import React from "react";
import About from "../../components/About";

export default function AboutUs() {
  document.title = "about-us || ls-healthcare";
  return (
    <div>
      <About />
    </div>
  );
}
