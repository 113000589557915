import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import useStyles from "../../../assets/styles/about";

const Banner = () => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.banner}>
      <Grid xl={8} className={classes.bannerText}>
        <Typography className={classes.bannerTitle}>OUR VISION</Typography>
        <Typography className={classes.bannerDesc}>
          A world with equal access to essential <br/>primary healthcare, regardless
          of who you are, what you earn, or where you live
        </Typography>
        <Grid xl={8} className={classes.bannerSubDesc}>
          <Typography>
            We’re working hand-in-hand with pharmacists, who are the everyday
            heroes of frontline healthcare in underserved markets.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Banner;
