import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popperContainer: {
    zIndex: 15000000,
    [theme.breakpoints.down('sm')]: {
      marginTop: '8rem',
      marginLeft: '17rem',
    },
  },
  wrapperPaper: {
    zIndex: 1500,
    boxShadow: '0px 2.96px 66.64px rgba(163, 171, 185, 0.24)',
    borderRadius: '12px',
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    position: 'absolute',
    borderBottom: '15px solid white',
    top: '-12px',
    right: '18px',
  },
}));
export default useStyles;
