import React from "react";
import {
  Button, Typography, Hidden, Grid, Box, Link, useMediaQuery
} from "@material-ui/core";
import lsFlowerHalf from "../assets/images/ls-flower-half.png";

import useStyles from "../assets/styles/welcome";

export default function Welcome() {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:991px)');

  const blogs = [
    {
      blog: "https://www.christenseninstitute.org/blog/innovators-creating-prosperity-lifestores-healthcare/",
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055138/Lifestores%20Healthcare%20Website/christensen_logo.svg",
      width: "11vw",
      width2: "32vw",
    },
    {
      blog: "https://www.startuphealth.com/lifestores",
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055138/Lifestores%20Healthcare%20Website/startup.svg",
      width: "7vw",
      width2: "26vw",
    },
    {
      blog: "https://fdhic.com/meet-the-founders-bryan-mezue-pharmacist-ken-ahaotu-and-andrew-garza-lifestores-healthcare/",
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055138/Lifestores%20Healthcare%20Website/flying_doctos.svg",
      width: "7.5vw",
      width2: "28vw",
    },
    {
      blog: "https://qz.com/africa/1808040/nigeria-pharmacy-starup-lifestores-raises-1-million-seed-round/",
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055138/Lifestores%20Healthcare%20Website/qz.svg",
      width: "7vw",
      width2: "28vw",
    },
    {
      blog: "https://www.obama.org/africa-2018/2018-africa-leaders/",
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055138/Lifestores%20Healthcare%20Website/obama.svg",
      width: "7vw",
      width2: "28vw",
    },
    {
      blog: "https://businessday.ng/features/article/anchors-and-pillars-pharmacies-the-mainstay-of-nigerias-healthcare/",
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055138/Lifestores%20Healthcare%20Website/businessday.svg",
      width: "9vw",
      width2: "34vw",
    },
    {
      blog: "https://disrupt-africa.com/2021/08/12/introducing-lifestores-healthcare/",
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055140/Lifestores%20Healthcare%20Website/disrupt.svg",
      width: "6vw",
      width2: "28vw",
    },
    {
      blog: "https://www.linkedin.com/posts/kofi-annan-award-for-innovation-in-africa_africa-pharmiq-pharmacists-activity-6946001712788774912-8K3m/?utm_source=share&utm_medium=member_android",
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055141/Lifestores%20Healthcare%20Website/koffi.svg",
      width: "6vw",
      width2: "28vw",
    },
    {
      blog: "https://www.ft.com/content/da2fdc82-fd3d-4628-a9dd-b83bb8a766d3",
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055141/Lifestores%20Healthcare%20Website/ft.svg",
      width: "8vw",
      width2: "32vw",
    },
    {
      blog: "https://techcrunch-com.cdn.ampproject.org/c/s/techcrunch.com/2022/06/22/african-healthtech-startups-in-the-supply-chain-segment-show-rapid-growth-spurring-a-7m-investment-initiative/amp/",
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055140/Lifestores%20Healthcare%20Website/tc.svg",
      width: "7vw",
      width2: "31vw",
    },
  ];
  const suppliersLogo = [
    'https://res.cloudinary.com/health-id/image/upload/v1594128801/Supplier%20Logo/Emzor_Logo.png',
    'https://res.cloudinary.com/health-id/image/upload/v1658146017/Supplier%20Logo/sygen.png',
    'https://res.cloudinary.com/health-id/image/upload/v1658146018/Supplier%20Logo/greenlife.jpg',
    'https://res.cloudinary.com/health-id/image/upload/v1663751974/Supplier%20Logo/Fidson_logo.png',
    'https://res.cloudinary.com/health-id/image/upload/v1658146017/Supplier%20Logo/vitabiotics.png',
  ];

  const backByLogo = [
    {
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055513/Lifestores%20Healthcare%20Website/google_startups.svg",
      link: "https://startup.google.com/",
      width: "16vw",
      width2: "36vw",
    },
    {
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055512/Lifestores%20Healthcare%20Website/startuo_health.svg",
      link: "https://www.startuphealth.com/lifestores",
      width: "8.5vw",
      width2: "30vw",
    },
    {
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055511/Lifestores%20Healthcare%20Website/flying_doctors.svg",
      link: "https://fdhic.com/meet-the-founders-bryan-mezue-pharmacist-ken-ahaotu-and-andrew-garza-lifestores-healthcare/",
      width: "8.8vw",
      width2: "28vw",
    },
    {
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055511/Lifestores%20Healthcare%20Website/consonance.svg",
      link: "https://consonanceinvest.com/",
      width: "8.5vw",
      width2: "30vw",
    },
    {
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055511/Lifestores%20Healthcare%20Website/kepple.svg",
      link: "https://kepple-africa-ventures.com/home_eng",
      width: "7.3vw",
      width2: "30vw",
    },
    {
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055511/Lifestores%20Healthcare%20Website/aruwa.svg",
      link: "https://www.aruwacapital.com/",
      width: "7vw",
      width2: "30vw",
    },
    {
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055511/Lifestores%20Healthcare%20Website/k50.svg",
      link: "https://k50ventures.com/",
      width: "7.4vw",
      width2: "26vw",
    },
    {
      logo: "https://res.cloudinary.com/health-id/image/upload/v1665055521/Lifestores%20Healthcare%20Website/cfao.svg",
      link: "https://www.cfaogroup.com/en/homepage/",
      width: "8vw",
      width2: "28vw",
    }
  ];

  const tabs = [
    { header: '100,000+', sub: 'Patients served monthly' },
    { header: '700+', sub: 'Pharmacies and', sub2: 'dispensaries supported' },
    { header: '7,000+', sub: 'Products sourced through', sub2: 'top-quality suppliers' },
    { header: '$1M+', sub: 'Saved for healthcare providers' },
    { header: '10%+', sub: 'Of pharmacies in Nigeria', sub2: 'served' },
  ]
  const PharmaList = [
    "Reduce their procurement costs by 10-20%",
    "Access credit",
    "Fast-track procurement process",
    "Use modern software to improve their pharmacy operations"
  ]
  const OfferList = [
    "High-quality & affordable care",
    "Delivery options",
    "Patient education programs",
  ]

  return (
    <div className={classes.root}>
      <Grid container className={classes.styledGrid}>
        <img alt="_ls_flower_" src={lsFlowerHalf} className={classes.leftImage} />
        <Grid item lg={6} md={12}>
          <Box className={classes.introHeader}>
            <Typography
              variant="h2"
              component="header"
              className={classes.headerText}
            >
              We are democratizing access to quality and affordable primary healthcare in Africa
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={6} md={12}>
          <img
            className={classes.introImage}
            alt="doctor_nurse"
            src="https://res.cloudinary.com/health-id/image/upload/v1664629747/Lifestores%20Healthcare%20Website/hero_images.png"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.statisticsContainer} >
        {tabs.map(({ header, sub, sub2 }) => (
          <Grid item container direction="column" className={classes.boxGrid}>
            <Typography className={classes.tabsHeader}>
              {header}
            </Typography>
            <Typography variant="caption" className={classes.tabsSubHeader}>
              {sub}
            </Typography>
            <Typography variant="caption" className={classes.tabsSubHeader} style={{ padding: 0 }}>
              {sub2}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container className={classes.forPharmacies1}>
        <Hidden mdDown>
          <Grid item lg={6}>
            <img
              alt="meds"
              src="https://res.cloudinary.com/health-id/image/upload/v1664710816/Lifestores%20Healthcare%20Website/pharmacies_and_hospitals.png"
              className={classes.imageDown}
            />
          </Grid>
        </ Hidden>
        <Grid item lg={6} md={12} className={classes.textGrid1}>
          <Typography
            variant="h4"
            component="header"
            className={classes.textHeader}
          >
            For Pharmacies & Hospitals
          </Typography>
          <Box className={classes.textBox}>
            <Typography variant="subtitle1" className={classes.textDesc}>
              Pharmacies and hospitals are the first port of call for primary
              healthcare, but are challenged by inefficient procurement
              practices, clunky inventory software, and personnel issues -
              reducing the time available to treat patients. Through our
              OGApharmacy platform, we help providers:
            </Typography>
            <ul className={classes.uList}>
              {PharmaList.map((item) => (
                <li key={item} className={classes.list}>
                  {item}
                </li>
              ))}
            </ul>
          </Box>
          <Button variant="contained" className={classes.learnMoreBtn}>
            <Link color="inherit" href="https://ogapharmacy.com/">
              Learn more
            </Link>
          </Button>
        </Grid>
        <Hidden lgUp>
          <Grid item lg={6}>
            <img
              alt="meds"
              src="https://res.cloudinary.com/health-id/image/upload/v1664710816/Lifestores%20Healthcare%20Website/pharmacies_and_hospitals.png"
              className={classes.imageDown}
            />
          </Grid>
        </ Hidden>
      </Grid>
      <Grid container className={classes.forPharmacies}>
        <Grid item container lg={6} md={12} className={classes.textGrid}>
          <Typography
            variant="h4"
            component="header"
            className={classes.textHeader}
            style={{ color: "#fff"}}
          >
            For Patients
          </Typography>
          <Box className={classes.textBox}>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.textDesc}
              style={{ color: "#fff" }}
            >
              Patients face difficulties accessing genuine & affordable
              medications when they need it, and struggle to plan for their
              healthcare needs. Through our Lifestores Pharmacy network &
              various direct-to-patient interventions, we offer:
            </Typography>
            <ul className={classes.uList}>
              {OfferList.map((item) => (
                <li key={item} className={classes.list} style={{ color: "#fff" }}>
                  {item}
                </li>
              ))}
            </ul>
          </Box>
        </Grid>
        <img
          alt="test"
          src={ isMobile
            ? "https://res.cloudinary.com/health-id/image/upload/v1665126974/Lifestores%20Healthcare%20Website/patients_image_mobile.png"
            : "https://res.cloudinary.com/health-id/image/upload/v1664790024/Lifestores%20Healthcare%20Website/patients_image.png"
          }
          className={classes.patientsImg}
        />
      </Grid>
      <Grid container direction="column" className={classes.featuredContainer}>
        <Grid item container direction="column">
          <Typography variant="h4" className={classes.mediaText}>
            Media Presence
          </Typography>
          <Typography variant="caption" className={classes.featureText}>
            As Featured In
          </Typography>
        </Grid>
        <Grid item container className={classes.blogContainer}>
          {blogs.map(({ blog, logo, width, width2}) => (
            <Link key={blog} href={blog} target="_blank" className={classes.blogLink}>
              <img
                src={logo}
                alt={logo}
                style={!isMobile ? { width } : {width: width2}}
              />
            </Link>
          ))}
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.investorsContainer}>
        <Typography variant="h4" className={classes.investorText}>
          Investors
        </Typography>
        <Typography variant="caption" className={classes.backText}>
          We are backed by world-class partners
        </Typography>
        <Grid item container className={classes.invListContainer}>
          {backByLogo.map(({ logo, width, width2, link }) => (
            <Link key={link} href={link} target="_blank" className={classes.iconsLink}>
              <img
                src={logo}
                alt="logo"
                style={!isMobile ? { width } : {width: width2}}
              />
            </Link>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
