import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  CList: {
    width: "16rem",
    padding: "24px 0",
  },
  CListItem: {
    padding: 0,
    "&:hover": {
        background: "#FEF3EB", // Set the background color you want on hover
      },
  },
  CListItemButton: {
    padding: "10px 32px",
  },
  CListItemText: {
    "& .MuiListItemText-primary": {
      fontSize: "16px",
      fontWeight: 400,
      color: "#606060",
      textTransform: "capitalize",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiListItemText-primary": {
        fontSize: "25px",
      },
    },
  },
  GridContainer: {
    padding: "15px 25px",
    width: "25rem",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      padding: "8px 12px",
      width: "20rem",
    },
  },
}));

export default useStyles;