import React from 'react';
import PropTypes from 'prop-types';
import { Grow, ClickAwayListener } from '@material-ui/core';
import { Popper, Paper, Box } from '@material-ui/core';
import useStyles from "../../assets/styles/popper";

export const RenderPopper = ({
  anchorEl, onClickAway, open, children, popperPlacement,
  modifiers, showArrow
}) => {
  const classes = useStyles();

  return (
    <Popper className={classes.PopperContainer}
      open={open}
      placement={popperPlacement}
      anchorEl={anchorEl}
      transition
      disablePortal
      modifiers={modifiers}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          {...placement}
          id="menu-list-grow"
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper className={classes.WrapperPaper} elevation={2}>
            <ClickAwayListener onClickAway={onClickAway}>
              {children}
            </ClickAwayListener>
            {(showArrow && <Box className={classes.Arrow} />)}
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

RenderPopper.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  onClickAway: PropTypes.func,
  children: PropTypes.node,
  popperPlacement: PropTypes.string,
  open: PropTypes.bool.isRequired,
  modifiers: PropTypes.object,
  showArrow: PropTypes.bool
};

RenderPopper.defaultProps = {
  onClickAway: () => null,
  anchorEl: <span />,
  popperPlacement: 'bottom',
  children: <span />,
  modifiers: {},
  showArrow: false
};
