import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#000",
    marginBottom: '7rem',
    [theme.breakpoints.down("md")]: {
      // backgroundImage: `url(${lsFlowerHalf})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    [theme.breakpoints.up("xxl")]: {
      minHeight: "calc(100vh - 100px)",
    },
  },
  introText: {
    color: "#121212",
    fontWeight: "400",
    fontSize: "20px",
    letterSpacing: "0.03em",
    lineHeight: "25px",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    }
  },
  leftImage: {
    width: "27vw",
    opacity: "0.2",
    position: "absolute",
    bottom: 0,
    [theme.breakpoints.down("md")]: {
      width: "78vw",
    }
  },
  styledGrid: {
    background: "#edfbf4",
    position: "relative",
    // background: "linear-gradient(135deg, rgba(201,253,207,1) 0%, rgba(255,255,255,1) 65%)",
    // [theme.breakpoints.down("md")]: {
    //   background: "#fff"
    // }
  },
  introHeader: {
    position: "relative",
    paddingTop: "15.6rem",
    paddingLeft: "9.5rem",
    [theme.breakpoints.down("md")]: {
      padding: "3rem 1.5rem 0"
    },
    "@media (width: 1024px)": {
      paddingLeft: "2rem"
    }
  },
  healthTech: {
    color: "#0DB458",
    fontSize: "40px",
    fontWeight: "800",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    }
  },
  hideImage: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    }
  },
  button: {
    backgroundColor: "#F58634",
    borderRadius: "20px",
    color: "#fff",
    fontSize: "1.5em",
    height: "70px",
    width: "250px",
    "&:hover": {
      backgroundColor: "#0DB458"
    }
  },
  sendGrid: {
    margin: "20px 10px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    }
  },
  statisticsContainer: {
    padding: "1.76rem 6rem 1.76rem 9.1rem",
    background: "#0DB458",
    [theme.breakpoints.down("md")]: {
      padding: "3.8rem 0",
    },
    "@media (min-width: 768px) and (max-width: 990px)": {
      padding: "1rem 2rem 1rem 6rem",
    },
    "@media (width: 1024px)": {
      padding: "2rem",
    }
  },
  boxGrid: {
    width: "20%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:not(:first-child)": {
        marginTop: "2.6rem"
      },
    },
    "@media (min-width: 768px) and (max-width: 990px)": {
      width: "30%",
    }
  },
  tabsHeader: {
    color: "#fff",
    fontSize: "3rem",
    fontWeight: "900",
    width: "100%"
  },
  tabsSubHeader: {
    color: "#fff",
    fontSize: ".98rem",
    fontWeight: "800",
    width: "100%",
    paddingTop: ".5rem"
  },
  forPharmacies: {
    position: "relative",
    marginTop: "5rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "10%",
      textAlign: "center",
    },
    "@media (min-width: 768px) and (max-width: 990px)": {
      height: "25rem",
    },
    "@media (width: 1024px)": {
      height: "35rem",
    }
  },
  learnMoreBtn: {
    fontSize: ".95rem",
    color: "#fff",
    fontWeight: 600,
    background: "#F58634",
    padding: "0.8rem 3.2rem",
    borderRadius: "9px",
    marginTop: "1rem",
    "&:hover": {
      background: "#F58634",
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: "6px",
      padding: "0.5rem 2.2rem",
      fontSize: "11px",
      marginTop: ".9rem",
    }
  },
  forPharmacies1:{
    marginTop: "3.75rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "10%",
      textAlign: "center",
    }
  },
  textGrid: {
    top: "9.5rem",
    left: "9.1rem",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      top: "8rem",
      left: "1.5rem",
      width: "90vw"
    },
    [theme.breakpoints.down("375.95")]: {
      top: "10rem",
    },
    [theme.breakpoints.down("320.95")]: {
      top: "8rem",
    }
  },
  textGrid1: {
    paddingTop: "1.8rem",
    paddingLeft: "4rem",
    [theme.breakpoints.down("md")]: {
      padding: "1.5rem",
      textAlign: "start"
    },
    "@media (width: 1024px)": {
      paddingTop: "1rem",
      paddingLeft: "6rem",
    }
  },
  textHeader: {
    fontWeight: "900",
    fontSize: "2.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.7rem",
      textAlign: "start"
    }
  },
  textDesc: {
    fontSize: "20px",
    lineHeight: "1.37",
    marginTop: "2rem",
    marginRight: "3rem",
    color: "#606060",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      marginTop: "1rem",
      fontWeight: "400",
      fontSize: "14px",
    }
  },
  uList: {
    padding: "1.8rem 1rem 0 1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("md")]: {
      padding: ".8rem 1rem 0 1rem",
      textAlign: "start"
    }
  },
  list: {
    fontSize: "20px",
    lineHeight: "1.5",
    color: "#606060",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "1.5",
    }
  },
  textBox: {
    display: "block",
    width: "94%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    }
  },
  imageDown: {
    marginLeft: "8.5rem",
    width: "34rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "1rem",
      marginLeft: "1.5rem",
      width: "23.5rem",
    },
    [theme.breakpoints.down("375.95")]: {
      marginTop: "1rem",
      marginLeft: "1.5rem",
      width: "21rem",
    },
    [theme.breakpoints.down("320.95")]: {
      marginTop: "1rem",
      marginLeft: "1.5rem",
      width: "17.5rem",
    },
    "@media (min-width: 768px) and (max-width: 990px)": {
      width: "45.5rem",
    },
    "@media (width: 1024px)": {
      marginLeft: "1.5rem",
    }
  },
  patientsImg: {
    width: "100%",
    zIndex: -1,
  },
  featuredContainer: {
    backgroundColor: "#F1FFF7",
    [theme.breakpoints.down("md")]: {
      // height: "250px"
    }
  },
  mediaText: {
    fontWeight: "700",
    fontSize: "32px",
    marginTop: "2.3rem",
    textAlign: "center",
    color: "#303030",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
      marginTop: "3rem",
    }
  },
  featureText: {
    fontWeight: "500",
    fontSize: "18px",
    color: "#606060",
    marginTop: ".5rem",
    textAlign: "center",
  },
  blogContainer: {
    padding: "2.5rem 3rem 3.2rem 6rem",
    [theme.breakpoints.down("md")]: {
      padding: "2.5rem 1.5rem .5rem 3rem",
    },
    [theme.breakpoints.down("375.95")]: {
      padding: "2.5rem 1rem .5rem 1.5rem",
    },
    [theme.breakpoints.down("320.95")]: {
      padding: "2.5rem .5rem .5rem 1rem",
    },
    "@media (min-width: 768px) and (max-width: 990px)": {
      padding: "2.5rem 1.5rem .5rem 6rem",
    }
  },
  blogLink: {
    "&:not(:last-child)": {
      marginRight: "1.1vw"
    },
    [theme.breakpoints.up("1441")]: {
      "&:not(:last-child)": {
        marginRight: "1.2vw",
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
      "&:not(:last-child)": {
        marginRight: "0",
        marginBottom: ".5rem"
      },
    },
    [theme.breakpoints.down("375.95")]: {
      width: "50%",
      "&:not(:last-child)": {
        marginRight: "0"
      },
    },
    [theme.breakpoints.down("320.95")]: {
      width: "50%",
      "&:not(:last-child)": {
        marginRight: "0"
      },
    }
  },
  investorsContainer: {
    marginTop: "2.8rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "3.5rem",
    }
  },
  investorText: {
    fontWeight: "700",
    fontSize: "32px",
    textAlign: "center",
    color: "#303030",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    }
  },
  backText: {
    fontWeight: "500",
    fontSize: "18px",
    color: "#606060",
    marginTop: ".5rem",
    textAlign: "center",
  },
  invListContainer: {
    padding: "2.2rem 3rem 3.2rem 7rem",
    [theme.breakpoints.down("md")]: {
      padding: "2.2rem 1.5rem .5rem 2rem",
    },
    [theme.breakpoints.down("375.95")]: {
      padding: "2.2rem 1rem .5rem 1.5rem",
    },
    [theme.breakpoints.down("320.95")]: {
      padding: "2.2rem .5rem .5rem 1rem",
    },
    "@media (min-width: 768px) and (max-width: 990px)": {
      padding: "2.2rem 3rem 3.2rem 8rem",
    }
  },
  iconGrid: {

  },
  iconsLink: {
    "&:not(:last-child)": {
      marginRight: "1.8vw"
    },
    [theme.breakpoints.up("1441")]: {
      "&:not(:last-child)": {
        marginRight: "2vw"
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
      "&:not(:last-child)": {
        marginRight: "0",
        marginBottom: ".5rem"
      },
    },
    [theme.breakpoints.down("375.95")]: {
      width: "50%",
      "&:not(:last-child)": {
        marginRight: "0"
      },
    },
    [theme.breakpoints.down("320.95")]: {
      width: "50%",
      "&:not(:last-child)": {
        marginRight: "0"
      },
    }
  },
  reduceImage: {
    [theme.breakpoints.down("md")]: {
      height: "80px",
      marginRight: "30px",
      width: "230px"
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
      marginRight: "30px",
      width: "120px",
    }
  },
  fidsonLogo: {
    [theme.breakpoints.down("md")]: {
        height: "70px",
        width: "100px",
    },
    [theme.breakpoints.down("sm")]: {
        height: "35px",
        width: "50px",
    }
  },
  vitabioticsLogo: {
    [theme.breakpoints.down("md")]: {
        height: "70px",
        width: "100px",
    },
    [theme.breakpoints.down("sm")]: {
        display: "none"
    }
  },
  backedByImage: {
    width: "80%",
  },
  kepple: {
    marginTop: "-20px"
  },
  headerText: {
    fontSize: "39px",
    fontWeight: "900",
    marginBottom: "30px",
    color: "#303030",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
      marginBottom: 0,
    }
  },
  contactBtn: {
    backgroundColor: "#F58634",
    borderRadius: "20px",
    color: "#fff",
    fontSize: "1.5em",
    height: "70px",
    width: "250px",
    "&:hover": {
      backgroundColor: "#0DB458"
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: "10px",
      fontSize: "13px",
      height: "37.55px",
      width: "134.11px",
    }
  },
  introImage: {
    margin: "3.8rem 2.5rem 1.4rem 0",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
      margin: "2.4rem 0 3.2rem 1.5rem",
    },
    [theme.breakpoints.down("375.95")]: {
      width: "22rem",
      margin: "2.4rem 0 3.2rem 1.5rem",
    },
    [theme.breakpoints.down("320.95")]: {
      width: "18rem",
      margin: "2.4rem 0 3.2rem 1.5rem",
    },
    "@media (width: 1024px)": {
      width: "30rem",
      margin: "2.4rem 1.5rem 3.2rem 0rem",
    }
  },
  goggleLogo: {
    "&>img": {
      [theme.breakpoints.down("sm")]: {
      display: "none"
      }
    }
  }
}));

export default useStyles;
