import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Menu, MenuItem, Typography } from "@material-ui/core";
import dropimg1 from "../../assets/images/dropImage1.PNG";
import dropimg2 from "../../assets/images/dropImage2.PNG";
import dropimg3 from "../../assets/images/IQ.PNG";
import useStyles from "../../assets/styles/navBar";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => {
  return (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorReference={props.width < 992 ? "anchorPosition" : "anchorEl"}
      anchorPosition={{ top: 60, left: 10000 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  );
});

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "& > *": {
      padding: 0,
      marging: 0,
    },
  },
}))(MenuItem);

export default function DropDown({ anchorEl, setAnchorEl, width }) {
  const classes = useStyles();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clickSolution = (link) => {
    window.location = `/solutions/${link}`;
  };

  return (
    <div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        width={width}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <div
            className={classes.dropdownButtonFirst}
            onClick={() => clickSolution("lifestore_pharmacy")}
          >
            <div>
              <img src={dropimg1} className={classes.dropimg} alt="" />
            </div>
            <div className={classes.dropdowntext}>
              <Typography className={classes.droptext}>
                Lifestores Pharmacy
              </Typography>
              <Typography className={classes.dropsubtext}>
                Discover a healthier you
              </Typography>
            </div>
          </div>
        </StyledMenuItem>
        <StyledMenuItem>
          <div
            className={classes.dropdownButton}
            onClick={() => clickSolution("oga_pharmacy")}
          >
            <div>
              <img src={dropimg2} className={classes.dropimg} alt="" />
            </div>
            <div className={classes.dropdowntext}>
              <Typography className={classes.droptext}>OGApharmacy</Typography>
              <Typography className={classes.dropsubtext}>
                By pharmacists, for pharmacies
              </Typography>
            </div>
          </div>
        </StyledMenuItem>
        <StyledMenuItem>
          <div
            className={classes.dropdownButtonLast}
            onClick={() => clickSolution("pharmiq")}
          >
            <div>
              <img src={dropimg3} className={classes.dropimg} alt="" />
            </div>
            <div className={classes.dropdowntext}>
              <Typography className={classes.droptext}>PharmIQ</Typography>
              <Typography className={classes.dropsubtext}>
                The heartbeat of smart pharmacies
              </Typography>
            </div>
          </div>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
