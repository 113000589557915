import React from "react";
import { Link, Typography, Grid, Hidden } from "@material-ui/core";
import rightArrowOrange from "../../assets/images/Learn more - Orange.png";
import useStyles from "../../assets/styles/lsPharmacy";
import Solutions from "../../components/Solution";
import everyDayLowPrices from "../../assets/images/Everyday low prices.png";
import medicinesYouCanTrust from "../../assets/images/Medicines you can trust.png";
import tailoredService from "../../assets/images/Tailored service.png";

const informations = [
  {
    icon: medicinesYouCanTrust,
    title: "Medicines you can trust",
    description: "We buy directly from manufacturers & importers",
  },
  {
    icon: everyDayLowPrices,
    title: "Everyday low prices",
    description: "We always give you the best value",
  },
  {
    icon: tailoredService,
    title: "Tailored service",
    description: "We help you to take control of your health",
  },
];

export default function LsPharmacy() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.upper}>
        <div className={classes.upperSection}>
          <img
            className={classes.heroImage}
            alt="Lifestores_Pharmacy_Solutions_Hero_Image"
            src="https://res.cloudinary.com/health-id/image/upload/v1613638251/Lifestores%20Healthcare%20Website/Lifestores_Pharmacy_Solutions_Hero_Image.png"
          />
          <div className={classes.left}>
            <div className={classes.mainLogoWrapper}>
              <img
                className={classes.mainLogo}
                alt="_ls_healthcare_logo"
                src="https://res.cloudinary.com/health-id/image/upload/v1613652127/Lifestores%20Healthcare%20Website/Lifestores_Pharmacy_Logo.png"
              />
            </div>
            <Typography
              variant="h2"
              component="header"
              className={classes.headings}
            >
              Discover a healthier you!
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.subtitle}
            >
              We know it can be difficult and expensive to find medications you
              can trust.
            </Typography>
            <Typography>
              <Link
                href="https://lifestorespharmacy.com/"
                className={`${classes.actions} ${classes.rightActionsHandler}`}
              >
                Get Started
                <img
                  className={classes.arrow}
                  alt="_arrow_"
                  src={rightArrowOrange}
                />
              </Link>
            </Typography>
          </div>
        </div>
        <div className={classes.imagesSection}>
          <img
            alt="Lifestores_Pharmacy_Solutions_Hero_Image_2"
            src="https://res.cloudinary.com/health-id/image/upload/v1613638326/Lifestores%20Healthcare%20Website/Lifestores_Pharmacy_Solutions_Hero_Image_2.png"
          />
        </div>
      </div>
      <div>
        <Grid container className={classes.middleSection}>
          <Grid item xs={12} md={6} className={classes.leftSection}>
            <div>
              <Typography
                variant="h2"
                component="header"
                color="secondary"
                className={classes.headings}
              >
                We are serving patients ubiquitously through our retail pharmacy
                chain
              </Typography>
              <Typography variant="subtitle1" className={classes.subtitle}>
                Lifestores Pharmacy is building a chain of affordable community
                pharmacies, and developing health programs to serve patients
                daily - directly and through our network of affiliates,
                targeting low-mid income communities.
              </Typography>
              <Typography color="secondary">
                <Link
                  color="inherit"
                  href="https://lifestorespharmacy.com/"
                  className={classes.actions}
                >
                  Learn more about Lifestores Pharmacy
                  <img
                    className={classes.arrow}
                    alt="_arrow_"
                    src={rightArrowOrange}
                  />
                </Link>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.yellow}>
              <div className={classes.imageWrapper}>
                <img
                  className={classes.image}
                  alt="_for_patients_avatar"
                  src="https://res.cloudinary.com/health-id/image/upload/v1613638455/Lifestores%20Healthcare%20Website/Lifestores_Pharmacy_Solutions_About_Image.png"
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.yellowSection}>
          {informations.map((information, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              className={classes.differences}
            >
              <div className={classes.valueLogoWrapper}>
                <img
                  alt="_icons+"
                  src={information.icon}
                  className={classes.valueLogo}
                />
              </div>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="secondary"
                className={classes.differenceHeading}
              >
                {information.title}
              </Typography>
              <Typography variant="body2" component="p" className={classes.difference}>
                {information.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Solutions />
      </div>
    </div>
  );
}
