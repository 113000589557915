import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
      paddingBottom: "4rem",
      "& > *": {
      letterSpacing: "0.031rem",
      lineHeight: "1.9rem",
      padding: "2rem 4rem",
      [theme.breakpoints.down("md")]: {
        padding: "0rem 1rem"
      }
    },
  },

  imgcontainer: {
    width: "100%",
    justifyContent: "space-between",
  },
  positionContainer: {
    marginTop: "5rem",
    [theme.breakpoints.down("lg")]: {
      marginTop: "2rem",
    }
  },
  openingHeader: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "30px",
    marginBottom: "3rem",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "1rem",
    }
  },
  openingImg:{
    width: "470px",
    height: "426px",
    [theme.breakpoints.down("lg")]: {
      width: "370px",
      height: "326px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  workingAt: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "40px",
    margin: "40px 0px",
    color: "#434452",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    }
  },
workingTest: {
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#434452",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    }
},
  aboutWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "85%",
    [theme.breakpoints.down("md")]: {
      display: "grid",
      width: "100%",
    }
  },
  groupText: {
    display: "flex",
    justifyContent: "space-between",
    background: "#f5f5f5",
    borderRadius: "15px",
    color: "#434452",
    padding: "2px 15px",
    "& > p": {
      paddingLeft: "15px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "2px",
      fontSize: "16px"
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      margin: "10px 0px",
    }
  },
  jobsContainer: {
    margin: "4rem 0",
  },
  jobs: {
    height: "152px",
    border: "1px solid rgba(67, 68, 82, 0.3)",
    borderRadius: "15px",
    padding: "1.5rem 4rem",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      height: "122px",
      width: "100%",

    },
  },
  jobDescription: {
    display: "flex",
    justifyContent: "space-between",
    width: "23%",
    [theme.breakpoints.down("lg")]: {
      width: "27%",
    },
    [theme.breakpoints.down("md")]: {
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  jobType: {
    justifyContent: "center",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "40px",
    color: "#121212",
    marginBottom: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      marginBottom: ".5rem",

    }
  },
  locationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    background: "#f5f5f5",
    borderRadius: "15px",
    color: "#434452",
    padding: "2px 15px",
    "& > p": {
      paddingLeft: "10px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "2px",
      fontSize: "16px"
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      margin: "2px",
    }
  },
  iconImage: {
    width: "1rem",
    height: "1.2rem",
    marginTop: "2px"
  },
}));

export default useStyles;
