import React from "react";
import { Grid, Typography, Link, Input, Button, InputAdornment } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useStyles from "../../assets/styles/footer";

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <footer className={classes.footer}>
      <Grid container className={classes.subscribeWrapper}>
        <Grid item className={classes.subContainer}></Grid>
        <Grid item container className={classes.emailWrapper}>
          <Grid item container md={6} xs={5}>
            <Typography className={classes.subscribeMainText}>Subscribe to Our Newsletter</Typography>
            <Typography className={classes.subscribeText}>stay up to date with our offers and other news</Typography>
          </Grid>
          <Grid item container md={6} xs={7}>
            <form onSubmit={(e) => history.push('/#')} className={classes.formWrapper}>
              <Input
                placeholder="Enter your email address"
                fullWidth
                disableUnderline
                className={classes.input}
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      src="https://res.cloudinary.com/health-id/image/upload/v1664867165/Lifestores%20Healthcare%20Website/mail_icon.png"
                      alt="mail icon"
                      className={classes.mail}
                    />
                  </InputAdornment>
                }
              />
              <Button variant="contained" type="submit" className={classes.button}>
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.mainWrapper}>
        <Grid item container md={3} xs={12} alignItems="center">
          <Typography component="div" className={classes.mainLogoWrapper}>
            <img
              className={classes.mainLogo}
              alt="_lifestore_logo"
              src="https://res.cloudinary.com/health-id/image/upload/v1611062792/Lifestores%20Healthcare%20Website/Lifestores_Healthcare_Logo.png"
            />
          </Typography>
        </Grid>
        <Grid item container md={3} xs={12} className={classes.LinksGrid}>
          <Typography className={classes.subHeadersTest} variant="body1">
            <Link color="inherit" href="/">
              Home
            </Link>
          </Typography>
          <Typography className={classes.subHeadersTest} variant="body1">
            <Link color="inherit" href="/about-us">
              About Us
            </Link>
          </Typography>
          <Typography className={classes.subHeadersTest} variant="body1">
            <Link color="inherit" href="/career">
              Careers
            </Link>
          </Typography>
          <Typography className={classes.subHeadersTest} variant="body1">
            <Link color="inherit" href="/privacy-policy">
              Privacy Policy
            </Link>
          </Typography>
        </Grid>
        <Grid item container md={3} xs={12} className={classes.ContactGrid}>
          <Grid item className={classes.circleGrid}>
            <img
              src="https://res.cloudinary.com/health-id/image/upload/v1664867165/Lifestores%20Healthcare%20Website/mail_icon.png"
              alt="mail icon"
              className={classes.lowerMail}
            />
          </Grid>
          <Typography className={classes.subHeadersTest} variant="body1">
            <Link  href="mailto:contact@lifestoreshealthcare.com" style={{ color: '#fff' }}>
              contact@lifestoreshealthcare.com
            </Link>
          </Typography>
        </Grid>
        <Grid item container md={3} xs={12} className={classes.addressGrid}>
          <Grid item className={classes.circleGrid}>
            <img
              src="https://res.cloudinary.com/health-id/image/upload/v1664874266/Lifestores%20Healthcare%20Website/home_icon.png"
              alt="mail icon"
              className={classes.lowerMail}
            />
          </Grid>
          <Grid item container xs={8} direction="column">
            <Typography className={classes.addressText} variant="body1">
              273 Borno way,
            </Typography>
            <Typography className={classes.addressText} variant="body1">
              Yaba, 101212, Lagos, Nigeria.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.socialsWrapper}>
        <Grid item className={classes.socialsGrid}>
          <Link href="https://web.facebook.com/lifestoresng/?_rdc=1&_rdr" target="_blank">
            <img
              src="https://res.cloudinary.com/health-id/image/upload/v1664874953/Lifestores%20Healthcare%20Website/facebook_icon.png"
              alt="facebook icon"
              className={classes.lowerIcons}
            />
          </Link>
        </Grid>
        <Grid item className={classes.socialsGrid}>
          <Link href="https://www.instagram.com/lifestoresng" target="_blank">
            <img
              src="https://res.cloudinary.com/health-id/image/upload/v1665502433/Lifestores%20Healthcare%20Website/instagram_icon.png"
              alt="instagram icon"
              className={classes.lowerIcons}
            />
          </Link>
        </Grid>
        <Grid item className={classes.socialsGrid}>
          <Link href="https://www.linkedin.com/company/lifestores-healthcare/" target="_blank">
            <img
              src="https://res.cloudinary.com/health-id/image/upload/v1664874953/Lifestores%20Healthcare%20Website/linkedin_icon.png"
              alt="linkedin icon"
              className={classes.lowerIcons}
            />
          </Link>
        </Grid>
        <Grid item className={classes.socialsGrid}>
          <Link href="https://twitter.com/LifestoresNG" target="_blank">
            <img
              src="https://res.cloudinary.com/health-id/image/upload/v1664874953/Lifestores%20Healthcare%20Website/Vector.png"
              alt="twitter icon"
              className={classes.lowerIcons}
            />
          </Link>
        </Grid>
      </Grid>
      <Typography variant="body2" className={classes.copyright}>
        <Link color="inherit" href="https://lifestorespharmacy.com/">
          {"© "}
          {new Date().getFullYear()}
          &nbsp;
          {"Lifestores Healthcare Holdings Inc., "}
        </Link>
        {"All Rights Reserved"}
      </Typography>
    </footer>
  );
}
