import { makeStyles } from "@material-ui/core/styles";
import Banner from "../images/banner.png";

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundImage: `url(${Banner})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "35rem",
    textAlign: "center",
    display:"flex",
    alignItems:"center"
  },
  bannerText: {
    color: "#FFF",
    margin: "0 auto",
  },
  bannerTitle:{
    fontWeight:"800",
    marginBottom:"1rem"

  },
  bannerDesc:{
    fontWeight:"900",
    fontSize: "2.2rem",
    lineHeight:"2.6rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.8rem",
    },
  },
  bannerSubDesc:{
    marginTop:"1rem",
    margin: "0 auto",

  },

  headingTag: {
    fontWeight: "bolder",
    marginBottom: "3rem",
    textAlign: "center",
    fontSize: "1.9rem",
    color: "#303030",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.8rem",
    },
  },
  padContainer: {
    padding: "3.5rem 9rem",
    [theme.breakpoints.down("md")]: {
      padding: "4rem 2rem",
    },
  },
  boxShadow: {
    background: "#FFFBF7",
    boxShadow: "0px 2.98643px 53.7557px #EDEDED",
  },

  boxCoreValues: {
    alignItems: "start",
    background: "#FFFBF7",
    boxShadow: "0px 2.98643px 53.7557px #EDEDED",
    height: "210px",
    [theme.breakpoints.down("sm")]: {
      height: "350px",
    },
  },
  coreVIcon: {
    marginBottom: "1rem",
  },
}));

export default useStyles;
