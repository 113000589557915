import React from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import ogapharmacy from "../assets/images/Ogapharmacy Logo.png";
import orangeArrow from "../assets/images/Learn more - Orange.png";
import blueArrow from "../assets/images/Learn more - Blue.png";
import IQ from "../assets/images/IQ.PNG";
import useStyles from "../assets/styles/solution";

export default function Solutions() {
  const classes = useStyles();
  return (
    <Grid container className={classes.solution}>
      <Grid item xs={12}>
        <Typography
          variant="h2"
          component="header"
          className={classes.solutionHeading}
        >
          Learn about our solutions
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} className={classes.solutions}>
        <div className={`${classes.lifestore} ${classes.webHolder}`}>
          <div>
            <img
              alt="_lifestore_logo"
              src="https://res.cloudinary.com/health-id/image/upload/v1612452325/Lifestores%20Healthcare%20Website/Lifestores_Pharmacy_Solution_Highlight.png"
            />
          </div>
          <div className={classes.logoWrapper}>
            <img
              className={classes.mainLogo}
              alt="_lifestore_logo"
              src="https://res.cloudinary.com/health-id/image/upload/v1613652127/Lifestores%20Healthcare%20Website/Lifestores_Pharmacy_Logo.png"
            />
          </div>
        </div>
        <Typography variant="subtitle1" className={classes.mainLogoText}>
          Discover a healthier you
        </Typography>
        <Typography>
          <Link
            href="/solutions/lifestore_pharmacy/"
            className={classes.actions}
            color="secondary"
          >
            <span
              className={`${classes.leftActionsHandler} ${classes.lifestoreLink}`}
            >
              Learn more about Lifestores pharmacy
            </span>
            <img className={classes.arrow} alt="_arrow_" src={orangeArrow} />
          </Link>
        </Typography>
        <Typography variant="subtitle1" component="div" className={classes.powerDiv}>
          <span className={classes.poweredText}>Powered by &nbsp;</span>
          <span className={classes.pharmText}>Pharm</span>
          <img className={classes.iq} alt="IQ" src={IQ} />
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} className={classes.solutions}>
        <div className={`${classes.webHolder} ${classes.ogapharm}`}>
          <div>
            <img
              alt="_lifestore_logo"
              src="https://res.cloudinary.com/health-id/image/upload/v1612452325/Lifestores%20Healthcare%20Website/OGApharmacy_Solution_Highlight.png"
            />
          </div>
          <div className={classes.logoWrapper}>
            <img
              className={classes.mainLogo}
              alt="_lifestore_logo"
              src={ogapharmacy}
            />
          </div>
        </div>
        <Typography variant="subtitle1" className={classes.mainLogoText}>
          By pharmacists, for pharmacies
        </Typography>
        <Typography>
          <Link
            href="/solutions/oga_pharmacy/"
            className={`${classes.actions} ${classes.ogapharmLink}`}
          >
            <span className={classes.leftActionsHandler}>
              Learn more about OGApharmacy
            </span>
            <img className={classes.arrow} alt="_arrow_" src={blueArrow} />
          </Link>
        </Typography>
        <Typography variant="subtitle1" component="div" className={classes.powerDiv}>
          <span className={classes.poweredText}>Powered by &nbsp;</span>
          <span className={classes.pharmText}>Pharm</span>
          <img className={classes.iq} alt="IQ" src={IQ} />
        </Typography>
      </Grid>
    </Grid>
  );
}
