import React from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import useStyles from "../../assets/styles/banner";

export default function Banner() {
  const classes = useStyles();

  return (
    <Grid  className={classes.root} position="absolute">
      <Typography ariant="p" component="p" className={classes.subtitleText}>
         We are proud to announce our $3 million pre-Series A raise to expand access to healthcare. 
         <Link
            className={classes.readMore}
            href="https://medium.com/@info_30468/lifestores-healthcare-closes-3m-pre-series-a-round-led-by-health54-c0cb1849ff60"
            target="_blank"
            rel="noopener"
            >
                <span className={classes.readMore}> Read more here</span>
            </Link>
      </Typography>
    </Grid>
  );
}
