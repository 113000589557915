import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../assets/styles/getInTouch";
import Form from "./form";

export default function Solutions() {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Grid container className={classes.background}>
        <Grid container className={classes.blue}>
          <Typography className={classes.header}>Get in touch</Typography>
          <Typography className={classes.subheader}>Send us a message and we’ll revert back to you soon</Typography>
        </Grid>
        <Form />
        <Grid container className={classes.white}></Grid>
      </Grid>
    </Grid>
  );
}
