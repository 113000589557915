import React from "react";
import Banner from "./components/Banner";
import Values from "./components/Values";
import WMUD from "./components/WMUD";
import Team from "./components/Team";

export default function About() {
  return (
    <>
      <Banner />
      <WMUD />
      <Team />
      <Values />
    </>
  );
}
