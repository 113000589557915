import React from 'react'
import { Typography, Link } from "@material-ui/core";
import useStyles from '../../assets/styles/contactUs';
export default function Contact() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h2" component="header" className={classes.headerText}>
               Do you have any idea
            </Typography>
            <Typography variant="h2" component="header" className={classes.headerText2}>
               or feedback for us?
            </Typography>
            <Typography variant="p" component="p" className={classes.subtitleText}>
               Please reach out to us on
               <Link
                className={classes.contactEmail}
                href="mailto:contact@lifestoreshealthcare.com"
               >
                  <span className={classes.contactEmail}> contact@lifestoreshealthcare.com</span>
               </Link>
            </Typography>
        </div>
    )
}
