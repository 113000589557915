import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  blue: {
    height: "60vh",
    backgroundColor: "#e5f7ed",
    display: "flex",
    flexDirection: "column",
    marginTop: "2rem",
    "@media (max-width: 991px)": {
      height: "100vh",
    },
    "@media (max-width: 360px)": {
      height: "130vh",
    },
    "@media (width: 1024px)": {
      height: "25vh",
    },
  },
  white: {
    height: "45vh",
    "@media (max-width: 991px)": {
      height: "120vh",
    },
    "@media (max-width: 360px)": {
      height: "130vh",
    },
    "@media (width: 1024px)": {
      height: "25vh",
    },
  },
  header: {
    marginLeft: "3rem",
    marginTop: "3rem",
    fontSize: "2.5rem",
    fontWeight: "600",
    letterSpacing: "1.25px",
    color: "#424242",
    "@media (max-width: 991px)": {
      marginLeft: "6vw",
    },
  },
  subheader: {
    marginLeft: "3rem",
    marginTop: "4px",
    letterSpacing: "0.5px",
    color: "#424242",
    "@media (max-width: 991px)": {
      marginLeft: "6vw",
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  form: {
    width: "89vw",
    zIndex: "9",
    position: "absolute",
    top: "183px",
    left: " 5rem",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 3px 6px #00000029",
    border: "0.5px solid #e2e2e2",
    "@media (max-width: 991px)": {
      height: "auto",
      top: "200px",
      left: " 5vw",
    },
    "@media (max-width: 250px)": {
      top: "250px",
    },
    "@media (width: 1024px)": {
      height: "fit-content",
    },
    "@media (min-width: 1440px)": {
      top: "18rem",
    },
  },
  inputs: {
    padding: "3rem",
    paddingBottom: "5rem",
    display: "block",
    flexDirection: "column",
  },
  contacts: {
    position: "relative",
    backgroundColor: "#00b050",
    color: "#ffffff",
    borderRadius: "0px 10px 10px 0px",
    padding: "3rem",
    "@media (max-width: 991px)": {
      height: "100vh",
      borderRadius: "0px 0px 10px 10px",
    },
  },
  inputsheader: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  sendIconImg: {
    height: "30px",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  sendIconImg2: {
    height: "85px",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  sendText: {
    fontSize: "2.2rem",
    fontWeight: "600",
    letterSpacing: "0.88px",
    color: "#424242",
  },
  inputsheaderImg: {
    //   marginRight:"-40%"
  },
  input: {
    marginTop: "2rem"
  },
  inputsfields: {
    display: "flex",
    "@media (max-width: 500px)": {
      display: "block",
    },
  },
  InputHeaders: {
    marginLeft: "10px",
    color: "#A3A3A3",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  normalInput: {
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    outline: "none",
    width: "100%",
    height: "50px",
    borderColor: "#f0f0f0",
    paddingLeft: "10px",
    marginBottom: "20px",
    "&:focus": {
      borderBottom: "solid #00b050",
    },
  },
  inputGrid: {
    // margin: "10px",
  },
  textareainput: {
    // margin: "10px",
    position: "relative",
  },
  textarea: {
    marginTop: "20px",
    paddingLeft: "10px",
    borderRight: "none",
    borderLeft: "none",
    borderTop: "none",
    borderWidth: "3px",
    borderColor: "#f0f0f0",
    outline: "none",
    width: "100%",
    height: "90px",
  },
  inputsThree: {
    paddingBottom: "50px",
  },
  b: {
    position: "absolute",
    zIndex: "20",
    right: 0,
    bottom: "-29px"
  },

  contactsHeaderText: {
    marginBottom: "3rem",
    fontSize: "35px",
    fontWeight: 600
  },
  contactsList: {
    // marginLeft: "30px",
  },
  contactsListItems: {
    width: "100%",
    marginRight: "5px",
    fontSize: "15px",
    letterSpacing: "0.5px",
    lineHeight: "40px",
    marginBottom: "2.5rem",
    display: "flex",
  },
  contactsListimg: {
    marginRight: "20px",
  },
  listImages: {
    height: "20px",
  },
  contactsIcons: {
    position: "absolute",
    bottom: "3rem",
    left: "3rem",
  },
  contactsListText: {
    wordBreak: "break-word",
    marginRight: "5px",
    fontSize: "1.3rem",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.2rem",
    },
  },
  icons: {
    height: "65px",
    marginRight: "25px",
  },
  //end of form
}));

export default useStyles;
