import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#0DB458",
        height:"67px",
        "@media (max-width: 360px)": {
            height:"90px",
        },
        "@media (width: 1024px)": {
            height:"110px",
        }
    },
    subtitleText: {
        fontSize: "1.5em",
        fontWeight: 600,
        marginTop: "15px",
        textAlign: "center",
        color: "rgba(255, 255, 255, 1)",
        [theme.breakpoints.down("md")]: {
            fontSize: "1em",
            fontWeight: 600,
            marginTop: "15px",
            width: "22rem",
            marginLeft: "20px"
        },
        "@media (min-width: 480px)": {
            marginLeft: "60px"
          },
        "@media (max-width: 360px)": {
            width: "20rem",
            fontWeight: 600,
            marginLeft: "20px"
        },
        "@media (max-width: 320px)": {
            width: "18rem",
            fontWeight: 600,
            marginLeft: "20px"
        },
        "@media (min-width: 768px) and (max-width: 990px)": {
            width: "40.5rem",
        },
        "@media (width: 1024px)": {
            marginTop: "35px",
            marginLeft: "10px",
            marginRight: "20px"
        }
    },
    readMore: {
        color: "rgba(245, 134, 46, 1)",
    },
}));

export default useStyles;