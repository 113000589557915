import React from "react";
import { Grid, Container, Typography, Link } from "@material-ui/core";
import { peoples } from "../variables";
import useStyles from "../../../assets/styles/about";

const Team = () => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.padContainer}>
      <Typography variant="h4" className={classes.headingTag}>
        Meet Our Leadership Team
      </Typography>

      <Grid
        container
        justifyContent="center"
        style={{ justifyContent: "center" }}
        spacing={2}
      >
        {peoples?.map((people) => {
          return (
            <Grid
              key={people?.id}
              item
              xs={12}
              md={3}
              xl={3}
              style={{ marginTop: "2rem", textAlign: "center" }}
            >
              <img
                src={people?.photo}
                alt="img"
                style={{ borderRadius: "50%" }}
              />
              <div style={{ marginTop: "1rem" }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    color: "#303030",
                  }}
                >
                  {people?.name}
                </Typography>
                <Typography>{people?.title}</Typography>
                <Link
                  href={people?.linkedInUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={people?.linkedin}
                    alt="img"
                    width={17}
                    style={{ marginTop: ".6rem" }}
                  />
                </Link>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Team;
