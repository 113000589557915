import React from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import useStyles from "../../assets/styles/getInTouch";
import sendIconImg from "../../assets/images/sendIcon.PNG";
import submitImg from "../../assets/images/submit.PNG";
import Green from "./green";

export default function Form() {
  const classes = useStyles();
  return (
    <Grid container className={classes.form}>
      <Grid container item xs={12} lg={7} className={classes.inputs}>
        <div className={classes.inputsheader}>
          <div item xs={12} lg={6} className={classes.inputsheadertext}>
            <Typography className={classes.sendText}>
              Send us a message
            </Typography>
          </div>
          <div className={classes.inputsheaderImg}>
            <img
              className={classes.sendIconImg}
              alt="_sendImage"
              src={sendIconImg}
            />
          </div>
        </div>
        <Grid container className={classes.input} spacing={4}>
          <Grid item container xs={12} lg={6} className={classes.inputGrid}>
            <TextField
              label="Full name"
              required
              placeholder="Your names"
              fullWidth
              InputLabelProps={{
                  shrink: true,
              }}
            />
          </Grid>
          <Grid item container xs={12} lg={6} className={classes.inputGrid}>
            <TextField
              label="Organization"
              placeholder="Your company name"
              fullWidth
              InputLabelProps={{
                  shrink: true,
              }}
            />
          </Grid>
          <Grid item container xs={12} lg={6} className={classes.inputGrid}>
            <TextField
              label="Phone"
              placeholder="e.g +234 800 000 1234"
              fullWidth
              InputLabelProps={{
                  shrink: true,
              }}
            />
          </Grid>
          <Grid item container xs={12} lg={6} className={classes.inputGrid}>
            <TextField
              label="Email"
              placeholder="Your email address"
              fullWidth
              InputLabelProps={{
                  shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.textareainput}>
            <TextField
              label="Message"
              placeholder="Your message"
              multiline
              rows={8}
              rowsMax={8}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              />
            <div className={classes.b}>
              <img
                className={classes.sendIconImg2}
                alt="_sendImage"
                src={submitImg}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Green />
    </Grid>
  );
}
