import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      letterSpacing: "0.031rem",
      lineHeight: "2.5rem",
    },
  },
  headings: {
    fontSize: "2.2rem",
    fontWeight: 600,
    [theme.breakpoints.up("xxl")]: {
      fontSize: "2.6rem",
    },
  },
  subtitle: {
    margin: theme.spacing(3, 0),
    fontSize: ".95rem",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.2rem",
    },
  },
  leftSection: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
    padding: "3rem 0 0",
    [theme.breakpoints.down("md")]: {
      padding: "0 2.0rem",
    },
  },
  rightSection: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
    padding: "3rem 0 0",
    [theme.breakpoints.down("md")]: {
      padding: "0 2.0rem",
    },
  },
  messageSection: {
    marginTop: "4rem",
    [theme.breakpoints.up("md")]: {
      margin: "4.5rem",
    },
  },
  imageTopWrapper: {
    padding: "0 2rem 2rem",
    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },
  imageWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    border: "solid 1px #dedede",
    padding: theme.spacing(1.5),
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
    boxShadow: "1px 1px 6px 0px #cccccc",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  image: {
    width: "100%",
    borderRadius: 0,
  },
  media: {
    background: "#FCFCFC",
    boxSizing: "border-box",
    padding: "5rem 0 4rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(6),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  mediaHeading: {
    fontSize: "2.2rem",
    fontWeight: 600,
    marginBottom: "2rem",
    textAlign: "center",
    textDecoration: "underline #00B050"
  },
  card: {
    maxWidth: 335,
    boxShadow: "0 0 10px #CCCCCC",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 400,
      margin: theme.spacing(2, 0),
    },
  },
  cardTitle: {
    fontSize: "1.0rem",
    fontWeight: "bold",
  },
  cardText: {
    "@media (max-width: 600px)": {
      maxHeight: "50px",
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.6rem",
    },
  },
  arrow: {
    width: "1.5rem",
    height: "1.5rem",
    marginLeft: ".7rem",
  },
  blogImage: {
    width: "50%",
    cursor: "pointer"
  },
  blogGrid: {
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default useStyles;
