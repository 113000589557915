import React from "react";
import {
  Grid, Typography,
} from "@material-ui/core";
import useStyles from "../../assets/styles/opening";
import OpeningImg from "../../assets/images/Rectangle 1016.png";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PeopleIcon from '@material-ui/icons/People';
import PlaceIcon from '@material-ui/icons/Place';
import LocationIcon from "../../assets/images/locationIcon.png";
import FolderIcon from "../../assets/images/FolderIcon.png"

export default function Openings() {
  const classes = useStyles();
  const jobs = [
    {
      name: "Front- End developer",
      description: "Full time",
      location: "Lagos"
    },
    {
      name: "Front- End developer",
      description: "Full time",
      location: "Lagos"
    },
    {
      name: "Front- End developer",
      description: "Full time",
      location: "Lagos"
    },
    {
      name: "Front- End developer",
      description: "Full time",
      location: "Lagos"
    },
  ];
  return (
    <div className={classes.root}>
      
      <Grid container>
        <Grid item container className={classes.imgcontainer}>
          <Grid item sm={12} lg={6} className={classes.positionContainer}>
            <Typography className={classes.openingHeader}>Open Positions</Typography>
            <Grid className={classes.aboutWrapper}>
              <span className={classes.groupText}> <InsertDriveFileIcon /> <Typography>Innovative Health Tech Experience </Typography></span>
              <span className={classes.groupText}><PeopleIcon /><Typography>5 - 20 people</Typography></span>
            </Grid>
            <Typography className={classes.workingAt}>Working at Lifestores</Typography>
            <Typography className={classes.workingTest}>Lifestores is looking for world class talent ready to tackle challenging projects
               that will ultimately build everyday lifestyle technology with the aim to simplify
                processes. thus improving systems or human lifespans, and increasing efficiency
            </Typography>
          </Grid>
          <img
            src={OpeningImg}
            alt="Img"
            className={classes.openingImg}
          />
        </Grid>
        <Grid container className={classes.jobsContainer}>
          {jobs.map((job) => (
            <Grid sm={12} className={classes.jobs}>
            <Typography className={classes.jobType}>{job.name}</Typography>
            <Grid className={classes.jobDescription}>
              <span className={classes.locationWrapper}>
              <img
                  className={classes.iconImage}
                  alt="_icon_"
                  src={FolderIcon}
                />
                <Typography>{job.description} </Typography></span>
              <span className={classes.locationWrapper}>
              <img
                  className={classes.iconImage}
                  alt="_icon_"
                  src={LocationIcon}
                />
                <Typography>{job.location}</Typography></span>
            </Grid>
          </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
