import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      letterSpacing: "0.031rem",
      lineHeight: "1.9rem",
    },
  },

  subtitle: {
    color: "#fff",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  privacySection: {
    margin: "0px",
    padding: "0px 100px 50px 100px",
    background: "#E5F7ED",
    paddingTop: "0px",
    paddingBottom: "9rem",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  privacyHeadings: {
    fontSize: "2rem",
    padding: "10px",
    fontWeight: 600,
    textAlign: "center",
    margin: theme.spacing(0,3),
    [theme.breakpoints.up("xxl")]: {
      fontSize: "2.5rem",
      margin: theme.spacing(1),
    },
  },
  privacyContent: {
    lineHeight: "1.7rem",
    fontSize: "18px",
    textAlign: "justify",
    '& h1': {
      padding: "10px 0px",
      fontSize: "2.5rem",
    },
    '& h2': {
      padding: "10px 0px",
      fontSize: "2rem",
    },
    '& ul': {
      paddingLeft: "40px",
    },
    '& a': {
      color: "#00b050",
      fontWeight: 600,
      width: "100%"
    },
    "@media (max-width: 768px)": {
      textAlign: "left",
      fontSize: "14px",
      '& h1': {
        padding: "5px 0px",
        fontSize: "1.5rem",
        textAlign: "left",
      },
      '& h2': {
        padding: "5px 0px",
        fontSize: "1.3rem",
        textAlign: "left",
      },
      '& ul': {
        paddingLeft: "30px",
      },

    },
  },
}));

export default useStyles;
