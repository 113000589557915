import { createMuiTheme } from "@material-ui/core/styles";

const appTheme = createMuiTheme({
  shadows: ["none"],
  palette: {
    primary: {
      main: "#00B050",
    },
    secondary: {
      main: "#F58634",
    },
    tertiary: {
      main: "#00b05017",
    },
    error: {
      main: "#FF4141",
    },
    text: {
      primary: "#424242",
      secondary: "#4B515D",
    },
    background: {
      default: "#FFFFFF",
      primary: "#00B05033",
    },
  },
  typography: {
    fontFamily: "Gilroy, Roboto, sans-serif",
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 320,
      sm: 600,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1600,
    },
  },
});

export default appTheme;
