import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "relative",
    padding: "8.3rem 4rem 2rem 9rem",
    backgroundColor: '#001405',
    [theme.breakpoints.down("md")]: {
      padding: "5rem 10px 2rem 1.5rem",
    },
  },
  subscribeWrapper: {
    position:  'absolute',
    top: '-7rem',
    left: '15vw',
    width: '69%',
    display: 'flex',
    height: '207px',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
    borderRadius: '4rem',
    boxShadow: '0px -2px 52px rgba(187, 187, 187, 0.25)',
    [theme.breakpoints.down("md")]: {
      top: '-5rem',
      left: '5vw',
      width: '90%',
      height: '8.5rem',
      borderRadius: '2rem',
      margin: '0',
    },
    [theme.breakpoints.down("375.95")]: {

    },
    [theme.breakpoints.down("320.95")]: {

    }

  },
  subContainer: {
    width: '41%',
    backgroundColor: '#0DB458',
    objectFit: 'fill',
    borderRadius: '20rem 12rem 12rem 20rem',
    [theme.breakpoints.down("md")]: {
      borderRadius: '2rem',
    },
  },
  emailWrapper: {
    position: 'absolute',
    top: '4.3rem',
    [theme.breakpoints.down("md")]: {
      height: '3rem',
      width: '95%',
      padding: '5px',
      top: '1.6rem',
      left: '1rem',
    },
  },
  formWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "394.56px",
    height: "56.71px",
    borderRadius: '42.53px',
    border: "0.708904px solid #FFFFFF",
    backgroundColor: ' #FFFFFF',
    padding: '5px 7px 5px 20px',
    marginLeft: "1rem",
    boxShadow: "7.08904px 14.1781px 35.4452px rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.down("md")]: {
      height: '2rem',
      padding: '2px',
      marginTop: '20px',
      marginLeft: "0"
    },
  },
  input: {
    backgroundColor: theme.palette.background.default,
    paddingRight: '20px',
    border: '0',
    borderRadius: '40px',
    fontSize: '.72rem',
    fontWeight: '400',
    color: '#000',
    [theme.breakpoints.down("md")]: {
      fontSize: '7.5px',
      paddingRight: '10px',
    },
  },
  mail: {
    width: ".95rem",
    paddingBottom: "4px",
    [theme.breakpoints.down("md")]: {
      width: ".7rem",
      paddingBottom: "3px",
      marginLeft: "8px"
    },
  },
  subscribeMainText: {
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#303030',
    paddingLeft: '4.6rem',
    [theme.breakpoints.down("md")]: {
      fontSize: '15px',
      lineHeight: '20px',
      paddingLeft: '0',
    },
  },
  subscribeText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12.8px',
    lineHeight: '160%',
    color: '#757575',
    paddingLeft: '4.6rem',
    [theme.breakpoints.down("md")]: {
      fontSize: '8.5px',
      lineHeight: '12px',
      paddingLeft: '0',
      paddingTop: '5px'
    },
  },
  button: {
    backgroundColor: '#FF6B0F',
    color: '#FFFFFF',
    fontSize: '13px',
    fontWeight: '600',
    borderRadius: '42.5px',
    padding: '10px 37px',
    [theme.breakpoints.down("md")]: {
      fontSize: '8px',
      padding: '5px 10px',
    },
  },
  mainWrapper: {
    color: '#FFFFFF',
    [theme.breakpoints.down("md")]: {
      // margin: '1rem',
    },
  },
  socialsWrapper: {
    justifyContent: "center",
    marginTop: "1.6rem",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      marginTop: "2.5rem",
    },
    "@media (min-width: 768px) and (max-width: 990px)": {
      padding: "0rem 0rem 0rem 15rem",
    }
  },
  mainLogoWrapper: {
    // padding: "1.2rem",

    // [theme.breakpoints.up("lg")]: {
    //   padding: "1rem",
    // },
  },
  mainLogo: {
    width: "12.5rem",
    // [theme.breakpoints.up("lg")]: {
    //   width: "53%",
    // },
  },
  subheaders: {
    fontWeight: "600",
    fontSize: "20px",
    paddingBottom: "1rem",
    [theme.breakpoints.down("md")]: {
      fontWeight: "400",
      fontSize: "18px",
    },
  },
  LinksGrid: {
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "1.8rem"
    },
    "@media (min-width: 768px) and (max-width: 990px)": {
      flexDirection: "row",
      marginLeft:"4rem",
      marginTop: "1.3rem"
      
    },
    "@media (width: 1024px)": {
      marginLeft:"4rem"
    }
  },
  ContactGrid: {
    alignItems: "center",
    justify: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginTop: "2.7rem"
    },
    "@media (min-width: 768px) and (max-width: 990px)": {
      flexDirection: "row",
      marginLeft:"3rem",
      marginTop: "0.5rem"
      
    },
    "@media (width: 1024px)": {
      marginLeft:"4rem"
    }
  },
  addressGrid: {
    alignItems: "center",
    justify: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginTop: "1rem"
    }
  },
  subHeadersTest: {
    fontSize: "14px",
    "&:not(:last-child)": {
      marginRight: "1rem"
    },
    [theme.breakpoints.down("md")]: {
      "&:not(:last-child)": {
        paddingBottom: "1rem"
      },
    },
  },
  addressText: {
    fontSize: "14px",
    "&:not(:last-child)": {
      marginRight: "1rem"
    },
    [theme.breakpoints.down("md")]: {
      "&:not(:last-child)": {
        paddingBottom: ".3rem"
      },
    },
  },
  lowerMail: {
    width: ".85rem"
  },
  lowerIcons: {
    width: "1.2rem"
  },
  circleGrid: {
    borderRadius: "50%",
    backgroundColor: "rgba(255, 255, 255, 0.14)",
    width: "2.2rem",
    height: "2.2rem",
    padding: "10px",
    marginRight: "1.2rem"
  },
  socialsGrid: {
    borderRadius: "50%",
    backgroundColor: "#fff",
    width: "3rem",
    height: "3rem",
    padding: "15px",
    marginRight: "8px",
  },
  bottomFlower: {
    position: "absolute",
    bottom: "-0.1rem",
    right: "4rem",
    width: "8rem",
    [theme.breakpoints.down("md")]: {
      right: "1.2rem",
      width: "6rem",
    },
  },
  icon: {
    margin: "7rem .9rem 2rem 0",
    width: "3.2rem",
  },
  iconImage: {
    width: "2rem",
    marginTop: ".8rem",
  },
  text: {
    margin: theme.spacing(2, 0),
    "& a": {
      textDecoration: "none",
      color: '#FFFFFF',
    },
    "&:first-of-type": {
      marginBottom: "15px",
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  middleSection: {
    paddingLeft: "4.7rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
    "& > *": {
      [theme.breakpoints.up("xxl")]: {
        fontSize: "1.4rem",
      },
    },
  },
  copyright: {
    textAlign: "center",
    marginTop: "1.5rem",
    fontSize: "14px",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      textAlign: "start",
      fontSize: "12px",
    },
    "@media (min-width: 768px) and (max-width: 990px)": {
      padding: "0rem 0rem 0rem 11.5rem",
    }
  },
}));

export default useStyles;
