import React from "react";
import Home from "../../components/Home";
import Welcome from "../../components/Welcome";

export default function Homepage() {
  return (
    <div>
      <Welcome />
      {/* <Home /> */}
    </div>
  );
}
