import React from "react";
import Career from "../../components/career/index";

export default function CareerPage() {
  document.title = "carrer|| ls-healthcare";
  return (
    <div>
      <Career />
    </div>
  );
}
